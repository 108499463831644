<div class="container xjob-padding mt-0 mb-0">
  <div *ngIf="!pageLoaded; else pageReady" class="loading-icon-wellProfile">
    <!-- <i class="fa fa-spinner fa-spin"
    aria-hidden="true"></i> -->
  </div>
  <ng-template #pageReady>
    <div class="container-fluid card-panels">
      <!-- title-->
      <div class="row">
        <div class="col-12">
          <div class="h1 page-name">{{'job.jobsDashboard'|translate}}</div>
        </div>
      </div>

      <div>
        <div class="dashboard-final-details">
            <div class="dashboard-jobcount">
              <label class="small">{{jobCount}} {{'job.jobs'|translate}}</label>
              <button class="bttn-rectangle bttn-tertiary" style="margin-left:18px;"
                (click)="openAddJobDashboardModal()">
                <span class="icon-unit-systems-add-icon add-icon-position"></span>
                <p class="default add-button">{{'job.'|translate}}</p>
              </button>
            </div>
          <div class="p-0 contain-toggle">
            <div>
              <span class="switch-label">{{'job.showAll'|translate}}</span>
    
              <label class="switch switch-icon">
                <input type="checkbox" name="settingsTheme" (change)="toggleChanged();"
                  [(ngModel)]="toggleModel">
                <span class="slider round"></span>
              </label>
    
              <span class="switch-label pl-1">{{'job.showRecent'|translate}}</span>
            </div>
          </div>
        </div>


      </div>

      <div class="mt-3" *ngIf="!viewGridMode">
        <app-list-view (row)="cardClick($event)" [data]="listJobs" [headers]="headers" [dataSource]=""
          [rowPointer]="false" [maxColumnDisplayLength]="20" [showToolTips]="2">
        </app-list-view>
      </div>
    </div>
  </ng-template>
</div>
<!-- <app-dialog 
  [data] = "dialogData"
  (onOK) = "confirmDialog()"
></app-dialog> -->