import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MsalInterceptor, MsalService, BroadcastService, MsalGuard } from '@azure/msal-angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule, routingRootComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptorProvider } from './core/services/error-interceptor-provider.service';
import { AddDashboardJobComponent } from './dashboard/add-dashboard-job/add-dashboard-job.component';
import { SharedModule } from './shared/shared.module';
import { MatIconModule } from '@angular/material/icon'
import { ListViewComponent } from './dashboard/list-view/list-view.component';
import { PageHasErrorsGuard } from './core/guards/page-has-errors.guard';
import { LogoutComponent } from './logout/logout.component';
import { AppInsightService } from '../app/core/services/AppInsight.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

import { CurrentUnitResolver } from './core/guards/resolve-unit.resolver';
import { JobCatalogsResolver } from './core/guards/resolve-jobcatalogs.resolver';
import { RigCatalogsResolver } from './core/guards/resolve-rigcatalogs.resolver';
import { WellHeaderCatalogsResolver } from './core/guards/resolve-wellheadercatalogs.resolver';
import { WellGeometryCatalogsResolver } from './core/guards/resolve-wellgeometrycatalogs.resolver';
import { TubularCatalogsResolver } from './core/guards/resolve-tubularcatalogs.resolver';
import { FluidSetCatalogsResolver } from './core/guards/resolve-fluidsetcatalogs.resolver';
import {IntervalCatalogsResolver} from './core/guards/resolve-intervalcatalogs.resolver';
//import { AppLoadService } from './app-load.service';
import { JobsTransferComponent } from './jobs-transfer/jobs-transfer.component';
import { CatalogsTransferComponent } from './catalogs-transfer/catalogs-transfer.component';
import { AnalysisCatalogsResolver } from './core/guards/resolve-analysiscatalogs.resolver';
import { FluidPropertiesCatalogResolver } from './core/guards/resolve-fluid-properties-catalog.resolver';
import { LocalTokenGuard } from './core/guards/localtoken.guard';
import { environment } from 'src/environments/environment';
import { LocalTokenInterceptor } from './core/services/localtoken.interceptor';
import { AuthorizationGuard } from './core/guards/authorization.guard';

environment.auth.intercept = environment.local ? LocalTokenInterceptor : MsalInterceptor;
console.log('auth.intercept app.module', environment.local ? "LocalTokenInterceptor" : "MsalInterceptor");
environment.auth.guard = environment.local ? LocalTokenGuard : MsalGuard;
console.log('auth.guard app.module', environment.local ? "LocalTokenGuard" : "MsalGuard");

const cloudProviders = [MsalGuard, MsalService, BroadcastService, AuthorizationGuard];
const localProviders = [LocalTokenGuard];
const appInsights = environment.local ? [] : [AppInsightService];
const appProviders = environment.local ? localProviders : cloudProviders;

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// export function appInitializerFn_LoadApp(loadService: AppLoadService) {
//   return () => loadService.loadApp();
// }
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    var res = appConfig.loadAppSettings("");
    return res;
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AddDashboardJobComponent,
    routingRootComponents,
    ListViewComponent,
    LogoutComponent,
    JobsTransferComponent,
    CatalogsTransferComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    MatIconModule,
    ModalModule,
    NgxSpinnerModule,
    NgxSmartModalModule.forRoot(),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    ...appInsights,
    ...localProviders,
    ...cloudProviders,
    //...appProviders,
    ...[
    HttpErrorInterceptorProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.auth.intercept,
      multi: true,
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    // AppLoadService, 
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFn_LoadApp,
    //   multi: true,
    //   deps: [AppLoadService], 
    // },
    DatePipe,
    PageHasErrorsGuard,
    CurrentUnitResolver,
    JobCatalogsResolver,
    RigCatalogsResolver,
    FluidSetCatalogsResolver,
    IntervalCatalogsResolver,
    WellGeometryCatalogsResolver,
    WellHeaderCatalogsResolver,
    TubularCatalogsResolver,
    AnalysisCatalogsResolver,
    FluidPropertiesCatalogResolver
  ]
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
