import 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

// Utils
import * as LS from 'src/app/utils/localstorage';
import * as OB from '../utils/objecter';
import { PARAM_MAP } from '../utils/constants';

// Services
import { UtilitiesService } from '../core/services/utilitiesservice.service';

// Models
import { ACTION_TYPE, FIELD_TYPE } from 'src/app/shared/enums/enums';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';
import { ToolbarEvent, ToolbarCatalog, ToolbarColumn, ToolbarSharing, ToolbarCache } from 'src/app/shared/components/app-table-toolbar/table-toolbar.component';
import { SettingsService } from '../core/services/settings.service';
import { Settings } from '../core/models/settingsModel.model';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {

  public toolbarEvent$ = new BehaviorSubject<ToolbarEvent>({ action: ACTION_TYPE.INIT });
  public disabledCatalog = false;
  public isReady: boolean = false;
  public userRoleForm: FormGroup;
  public pageLoaded: boolean = false;
  public toolbarSharing: ToolbarSharing = new ToolbarSharing();
  public componentDestroyed$: Subject<boolean> = new Subject();
  public columns: Array<ToolbarColumn> = [
    { key: "userId", i18n: "User", fieldType: FIELD_TYPE.TEXT, readonly: true },
    { key: "assignedRole", i18n: "Role", fieldType: FIELD_TYPE.DROPDOWN, data: ["Admin"]}
  ];
  public catalog: ToolbarCatalog = {
    header: [
      { title: 'User', uom: false, key: "userId", type: 'text'},
      { title: 'Active Timezone', uom: false, key: "activeTimezone", type: 'text'}],
    data: []
  }
  public tableView: Array<Settings> = [];
  public users: Array<Settings> = [];
  public get validationFn() {
    return this.toolbarSharing.tableView.filter((el, idx) => idx != this.toolbarSharing.indexEditing);
  }

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public _utilities: UtilitiesService,
    public _setting: SettingsService,
  ) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.isReady = false;

    this.initializeForm();
    this.getUsers();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public getUsers() {
    this._utilities.showSpinner(true);
    this._setting.getAllProfileSettings().subscribe(res => {
      this.users = res;
      this.catalog.data = res;
      this.tableView = res.filter(x=> !OB.isNull(x.assignedRole));
      this.isReady = true;
      this._utilities.showSpinner(false);
    });
  }

  get validationDataFn() {
    return this.tableView.filter((el, idx) => idx != this.toolbarSharing.indexEditing);
  }

  public initializeForm() {
    this.userRoleForm = this.fb.group({
      userId: ['', [Validators.required, ValidatorsEx.notDuplicate(this, "validationDataFn")]],
      assignedRole: ['', Validators.required]
    });
  }

  public handleSelectCatlog(row) {

  }

  public handleAdd(event) {
    let { action, originDataUom } = event;
    let payload = this.buildPayload(originDataUom);
    let req = this._setting.updateUserRoleAsync(payload);

    this._utilities.promiseAll([req], ["User Role"]).then(success => {
      this.disabledCatalog = false;
      this.toolbarEvent$.next({ action: action, status: success, data: originDataUom });
    });
  }

  public handleReset() {
    this.disabledCatalog = false;
  }

  public handleEdit(event) {
    this.disabledCatalog = true;
    this.toolbarEvent$.next({ action: ACTION_TYPE.SHOW_CATALOG, data: false});
  }

  public handleDelete(event) {
    let { originDataUom } = event;
    let payload = this.buildPayload({userId: originDataUom.userId, assignedRole: ""});
    let req = this._setting.updateUserRoleAsync(payload);
    
    this._utilities.promiseAll([req], ["User Role"]).then(success => {
      this.toolbarEvent$.next({ action: ACTION_TYPE.DELETE, status: success });
    });
  }

  private buildPayload(userRole) {
    return {
      userId: userRole.userId,
      assignedRole: userRole.assignedRole,
      modifiedBy: this._utilities.currentUser.userId
    }
  }

}
