import { Component, OnInit } from '@angular/core';
import { PAGES } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onLogin(event) {
    window.location.href = PAGES.JobsDashboard; //Fixes can't navigate with router
  }
}
