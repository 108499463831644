import { UUID } from 'angular2-uuid';
import { UnitValue } from 'src/app/core/models/common.model';

// rig
export class Rig {
    jobId: string = "";
    rigWITSML: rigWITSML = new rigWITSML();
    rigUtilization: rigUtilization = new rigUtilization();
}
export class rigWITSML {
    rigId: string = "";
    title: string = "";
    owner: string = "";
}
export class rigUtilization {
    rigUtilizationWITSML: rigUtilizationWITSML = new rigUtilizationWITSML();
}
export class rigUtilizationWITSML {
    rigUtilizationId: string = "";
    solidsControlEquipment: solidsControlEquipmentRig = new solidsControlEquipmentRig();
    airGap: uomValue = new uomValue({ value: 0, uom: "ft" });
    //airGap: uomValue2 = new uomValue2({ Value: 0, Uom: "ft" });
}

export class solidsControlEquipmentRig {
    solidsControlEquipmentId: string = "";
}

// solids composite for all styles
export class SolidsControlEquipment {
    rigId: string = "";
    rigUtilizationId: string = UUID.UUID();
    solidsControlEquipmentId: string = UUID.UUID();
    style: string = "";
    class: string = "";
    equipmentId: string = "";
    manufacturer: string = "";
    model: string = "?";
    mudCleanerId: string = UUID.UUID();
    screenLayout: string = "";
    numberOfScreens: number = 0;
    nominalRPM: uomValue = new uomValue({ value: 0, uom: "rpm" });
    capFlow: uomValue = new uomValue({ value: 0, uom: "bpm" });
    desanderConeSize: uomValue = new uomValue({ value: 0, uom: "in" });
    desanderNumCones: number = 0;
    desilterConeSize: uomValue = new uomValue({ value: 0, uom: "in" });
    desilterNumCones: number = 0;
    desilter: SCE_Desilter = new SCE_Desilter();
    desander: SCE_Desander = new SCE_Desander();
    shaker: SCE_Shaker = new SCE_Shaker();
    centrifugeWITSML: solids_CentrifugeWITSML = new solids_CentrifugeWITSML();
    degasserWITSML: solids_DegasserWITSML = new solids_DegasserWITSML();
    hydrocycloneWITSML: solids_HydrocycloneWITSML = new solids_HydrocycloneWITSML();
    mudCleanerWITSML: solids_MudCleanerWITSML = new solids_MudCleanerWITSML();
    shakerWITSML: solids_ShakerWITSML = new solids_ShakerWITSML();
}
export class solids_CentrifugeWITSML {
    centrifugeId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "?";
    capFlow: uomValue = new uomValue({ value: 0, uom: "bpm" });
}
export class solids_DegasserWITSML {
    degasserId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "?";
}
export class solids_HydrocycloneWITSML {
    hydrocycloneId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "?";
}
export class solids_ShakerWITSML {
    shakerId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "?";
}
export class solids_MudCleanerWITSML {
    mudCleanerId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "?";
}

// solids export
export class SCE_common {
    rigId: string = "";
    rigUtilizationId: string = "";
    solidsControlEquipmentId: string = "";
    style: string = null;
    //equipmentId: string = null;
}
export class SCE_Centrifuge extends SCE_common {
    nominalRPM: uomValue = new uomValue({ value: 0, uom: "rpm" });
    centrifugeWITSML: solids_CentrifugeWITSML = new solids_CentrifugeWITSML();
}
export class SCE_Desander extends SCE_common {
    desanderConeSize: uomValue = new uomValue({ value: 0, uom: "in" });
    desanderNumCones: number = null;
    hydrocycloneWITSML: solids_HydrocycloneWITSML = new solids_HydrocycloneWITSML();
}
export class SCE_Desilter extends SCE_common {
    desilterConeSize: uomValue = new uomValue({ value: 0, uom: "in" });
    desilterNumCones: number = null;
    hydrocycloneWITSML: solids_HydrocycloneWITSML = new solids_HydrocycloneWITSML();
}
export class SCE_Degasser extends SCE_common {
    class: string = "";
    degasserWITSML: solids_DegasserWITSML = new solids_DegasserWITSML();
}
export class SCE_MudCleaner extends SCE_common {
    mudCleanerId: string = null;
    manufacturer: string = "";
    model: string = "?";
    desilter: {
        desilterConeSize: uomValue;
        desilterNumCones: number;
    };
    desander: {
        desanderConeSize: uomValue;
        desanderNumCones: number;
    };
    shaker: {
        screenLayout: string;
        numberOfScreens: number;
    };
    constructor() {
        super();
        this.desilter = { desilterConeSize: new uomValue({ value: 0, uom: "in" }), desilterNumCones: 0 }
        this.desander = { desanderConeSize: new uomValue({ value: 0, uom: "in" }), desanderNumCones: 0 }
        this.shaker = { screenLayout: null, numberOfScreens: 0 }
    }
}
export class SCE_Shaker extends SCE_common {
    screenLayout: string = "";
    numberOfScreens: number = 0;
    shakerWITSML: solids_ShakerWITSML = new solids_ShakerWITSML();
}


// mudlift pump
export class MudLiftPump {
    rigId: string = "";
    rigUtilizationId: string = "";
    mudLiftPumpId: string = UUID.UUID();
    holdPressure: uomValue = new uomValue({ value: 0, uom: "psi" });
    id: uomValue = new uomValue({ value: 0, uom: "in" });
    od: uomValue = new uomValue({ value: 0, uom: "in" });
    len: uomValue = new uomValue({ value: 0, uom: "ft" });
    mlpDepth: uomValue = new uomValue({ value: 0, uom: "ft" });
}


// pits
export class Pit {
    pitName: string = "";
    pitStyle: string = "";
    useUllage: boolean = false;
    directMeasure: boolean = false;
    length: uomValue = new uomValue({ value: 0, uom: "ft" });
    width: uomValue = new uomValue({ value: 0, uom: "ft" });
    height: uomValue = new uomValue({ value: 0, uom: "ft" });
    secondaryHeight: uomValue = new uomValue({ value: 0, uom: "ft" });
    depth: uomValue = new uomValue({ value: 0, uom: "ft" });
    diameter: uomValue = new uomValue({ value: 0, uom: "ft" });
    secondaryDiameter: uomValue = new uomValue({ value: 0, uom: "ft" });
    volume: uomValue = new uomValue({ value: 0, uom: "bbl" });
    onRig: boolean = false;
    rigId: string = "";
    rigUtilizationId: string = "";
    pitWITSML: pitWITSML = new pitWITSML();
}
export class pitWITSML {
    pitId: string = UUID.UUID();
    isActive: boolean = false;
    capMx: uomValue = new uomValue({ value: 0, uom: "bbl" });
}
// model for the formgroup controls
export class PitInput extends Pit {
    pitId: string = UUID.UUID();
    isActive: boolean = false;
    capMx: uomValue = new uomValue({ value: 0, uom: "bbl" });
}

// pitvolume
export class PitVolume {
    PitVolume: uomValue = new uomValue({ value: 0, uom: "bbl" });
    PitMaxCapacity: uomValue = new uomValue({ value: 0, uom: "bbl" });
}
export class PitVolumeUomValue {
    Value: number = 0;
    Uom: string = "";
    public constructor(init?: Partial<PitVolumeUomValue>) {
        Object.assign(this, init);
    }
}
export class PitVolumeInput {
    UseUllage: boolean = false;
    Length: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    Width: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    Height: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    SecondaryHeight: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    Depth: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    Diameter: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
    SecondaryDiameter: PitVolumeUomValue = new PitVolumeUomValue({ Value: 0, Uom: "ft" });
}


// pumps
export class Pump {
    rigId: string = "";
    rigUtilizationId: string = "";
    mudPumpWITSML: pumpWITSML = new pumpWITSML();
    // strokeRate: uomValue = new uomValue({ value: 0, uom: "spm" });
    // pressure: uomValue = new uomValue({ value: 0, uom: "psi" });
    // power: uomValue = new uomValue({ value: 0, uom: "hp" });
    // percent: uomValue = new uomValue({ value: 0, uom: "i%n" });
    // diameter: uomValue = new uomValue({ value: 0, uom: "in" });
}
export class pumpWITSML {
    mudPumpId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "";
    typePump: string = "";
    lenStroke: uomValue = new uomValue({ value: 0, uom: "in" });
    odRod: uomValue = new uomValue({ value: 0, uom: "in" });
    idLiner: uomValue = new uomValue({ value: 0, uom: "in" });
    eff: uomValue = new uomValue({ value: 0, uom: "%" });
    presMx: uomValue = new uomValue({ value: 0, uom: "psi" });
    powHydMx: uomValue = new uomValue({ value: 0, uom: "hp" });
    spmMx: uomValue = new uomValue({ value: 0, uom: "spm" });
}

export class PumpCaseSetup {
    pump: PumpModel;
    isSelected: boolean;
}

export class PumpModel {
    mudPumpId: string = UUID.UUID();
    manufacturer: string = "";
    model: string = "";
    typePump: string = "";
    lenStroke: number;
    odRod: number;
    idLiner: number;
    spmMx: number;
    powHydMx: number;
    position: string;
    strokeRate: number;
    maxHHP: number;
    efficiency: number;
    maxPressure: number;
    bPS: number;
    maxFlowRate: number;
}

export class PumpMapper {
    public static mapToModel(item): PumpModel {
        let pumpModel = new PumpModel();
        let mudPumpWITSML = item?.mudPumpWITSML;
        pumpModel.mudPumpId = mudPumpWITSML?.mudPumpId;
        pumpModel.manufacturer = mudPumpWITSML?.manufacturer;
        pumpModel.model = mudPumpWITSML?.model;
        pumpModel.typePump = mudPumpWITSML?.typePump;
        pumpModel.lenStroke = mudPumpWITSML?.lenStroke?.value || 0;
        pumpModel.odRod = mudPumpWITSML?.odRod?.value || 0;
        pumpModel.idLiner = mudPumpWITSML?.idLiner?.value || 0;
        pumpModel.spmMx = mudPumpWITSML?.spmMx?.value || 0;
        pumpModel.powHydMx = mudPumpWITSML?.powHydMx?.value || 0;
        pumpModel.position = item?.position || "Primary";
        pumpModel.strokeRate = item?.strokeRate?.value || 0;
        pumpModel.maxHHP = item?.maxHHP?.value || 0;
        pumpModel.efficiency = item?.efficiency?.value || 0;
        pumpModel.maxPressure = item?.maxPressure?.value || 0;
        pumpModel.bPS = item?.bPS?.value || 0;
        pumpModel.maxFlowRate = item?.maxFlowRate?.value || 0;
        return pumpModel;
    } 
}

// model for the formgroup controls
export class PumpInput extends pumpWITSML {
    rigId: string = "";
    rigUtilizationId: string = "";
}

// service line
export class ServiceLine {
    rigId: string = "";
    rigUtilizationId: string = "";
    bopWITSML: service_BopWITSML = new service_BopWITSML();
}
export class service_BopWITSML {
    bopId: string = UUID.UUID();
    odBoosterLine: uomValue = new uomValue({ value: 0, uom: "in" });
    idBoosterLine: uomValue = new uomValue({ value: 0, uom: "in" });
    lenBoosterLine: uomValue = new uomValue({ value: 0, uom: "ft" });
    odChkLine: uomValue = new uomValue({ value: 0, uom: "in" });
    idChkLine: uomValue = new uomValue({ value: 0, uom: "in" });
    lenChkLine: uomValue = new uomValue({ value: 0, uom: "ft" });
    odKillLine: uomValue = new uomValue({ value: 0, uom: "in" });
    idKillLine: uomValue = new uomValue({ value: 0, uom: "in" });
    lenKillLine: uomValue = new uomValue({ value: 0, uom: "ft" });
}
// model for the formgroup controls
export class ServiceLineInput extends service_BopWITSML {
    rigId: string = "";
    rigUtilizationId: string = "";
}

// surface equipment
export class SurfaceEquipment {
    rigId: string = "";
    rigUtilizationId: string = "";
    surfaceEquipmentWITSML: SurfaceEquipmentWITSML = new SurfaceEquipmentWITSML();
}
export class SurfaceEquipmentWITSML {
    surfaceEquipmentId: string = UUID.UUID();
    presRating: uomValue = new uomValue({ value: 0, uom: "psi" });
    typeSurfEquip: string = "";
    useStandpipe: boolean = true;
    useHose: boolean = true;
    useSwivel: boolean = true;
    useKelly: boolean = true;
    idStandpipe: uomValue = new uomValue({ value: 0, uom: "in" });
    lenStandpipe: uomValue = new uomValue({ value: 0, uom: "ft" });
    idHose: uomValue = new uomValue({ value: 0, uom: "in" });
    lenHose: uomValue = new uomValue({ value: 0, uom: "ft" });
    idSwivel: uomValue = new uomValue({ value: 0, uom: "in" });
    lenSwivel: uomValue = new uomValue({ value: 0, uom: "ft" });
    idKelly: uomValue = new uomValue({ value: 0, uom: "in" });
    lenKelly: uomValue = new uomValue({ value: 0, uom: "ft" });
    odReel: uomValue = new uomValue({ value: 0, uom: "in" });
    odCore: uomValue = new uomValue({ value: 0, uom: "in" });
    widReelWrap: uomValue = new uomValue({ value: 0, uom: "in" });
    lenReel: uomValue = new uomValue({ value: 0, uom: "ft" });
}


// generic
export class uomValue {
    value: number = 0;
    uom: string = "";
    public constructor(init?: Partial<uomValue>) {
        Object.assign(this, init);
    }
}

export class PumpPerformance {
    strokeRate: UnitValue;
    odRod: UnitValue;
    idLiner: UnitValue;
    eff: UnitValue;
    lenStroke: UnitValue;
    typePump: string;
};

export class PumpPerformanceResponse {
    pumpRate: number;
    volumePerStroke: number;
    error: string;
    constructor(data) {
        this.pumpRate = data?.PumpRate?.Value || 0;
        this.volumePerStroke = data?.VolumePerStroke?.Value || 0;
        this.error = data.error || "";
    }
};
