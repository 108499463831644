import { Injectable } from '@angular/core';
import { convertedUOMvalue, FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import * as UC from 'units-converter';
import { BehaviorSubject, Subject } from 'rxjs';
import { UnitSystem } from 'src/app/shared/models/uom-models';
import * as OB from 'src/app/utils/objecter';
import { API_UNITS } from 'src/app/utils/constants';

@Injectable({
  providedIn: 'root'
})

export class UnitConversionService {
  public componentDestroyed$: Subject<boolean> = new Subject();
  public currentUnitSystem$ = new BehaviorSubject<UnitSystem>(null);

  constructor(
    ) { }

  public convertValue(uomConversion: FieldLevelUoMSettings, value: any) {

    if (uomConversion === undefined) {
      return value;
    }
    if (uomConversion.currentUnit === uomConversion.unit) {
      return value;
    }
    if (value === 0 && uomConversion.quantity != 'Temperature') {
      return value;
    }

    // let currentUnitSystem: any = this.currentUnitSystem$.value
    let convertedValue: any
    let selectedUnit: string

    selectedUnit = uomConversion.currentUnit;

    switch (uomConversion.quantity) {
      case 'Depth':
        convertedValue = UC.depth(value).from(uomConversion.unit).to(selectedUnit);
        convertedValue.value = OB.round(convertedValue.value, 10);
        break;
      case 'Diameter':
        convertedValue = UC.diameter(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Length':
        convertedValue = UC.length(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Volume':
        convertedValue = UC.volume(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Density':
        convertedValue = UC.density(value).from(uomConversion.unit).to(selectedUnit);
        convertedValue.value = this.roundDensity(convertedValue.value, selectedUnit);
        break;
      case 'Percent':
        convertedValue = UC.percent(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'StrokeRate':
        convertedValue = UC.strokeRate(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Rate':
        convertedValue = UC.rate(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'WeightPerLength':
        convertedValue = UC.weightPerLength(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Pressure':
        convertedValue = UC.pressure(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Area':
        convertedValue = UC.area(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Temperature':
        convertedValue = UC.temperature(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Force':
        convertedValue = UC.force(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Dogleg':
        convertedValue = UC.dogleg(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Concentration':
        convertedValue = UC.concentration(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Time':
        convertedValue = UC.time(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Frequency':
        convertedValue = UC.frequency(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'MagneticFluxDensity':
        convertedValue = UC.magnecticFluxDensity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Power':
        convertedValue = UC.power(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'PressureDropPerLength':
        convertedValue = UC.pressurePerLength(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'RateOfPenetration':
        convertedValue = UC.rateOfPenetration(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Resistance':
        convertedValue = UC.resistance(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Resistivity':
        convertedValue = UC.resistivity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ShearRate':
        convertedValue = UC.shearRate(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ShearStress':
        convertedValue = UC.shearStress(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'SpecificHeatCapacity':
        convertedValue = UC.specificHeatCapacity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Tau0':
        convertedValue = UC.tau0(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ThermalConductivity':
        convertedValue = UC.thermalConductivity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ThermalDiffusivity':
        convertedValue = UC.thermalDiffusivity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ThermalExpansion':
        convertedValue = UC.thermalExpansion(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Torque':
        convertedValue = UC.torque(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Velocity':
        convertedValue = UC.velocity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Viscosity':
        convertedValue = UC.viscosity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'VolumePerStroke':
        convertedValue = UC.volumePerStroke(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Weight':
        convertedValue = UC.weight(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Acceleration':
        convertedValue = UC.acceleration(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Angle':
        convertedValue = UC.angle(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Capacity':
        convertedValue = UC.capacity(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'Compressibility':
        convertedValue = UC.compressibility(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'ForcePerLength':
        convertedValue = UC.forcePerLength(value).from(uomConversion.unit).to(selectedUnit);
        break;
      case 'RotarySpeed':
        convertedValue = UC.rotarySpeed(value).from(uomConversion.unit).to(selectedUnit);
        break;
      default:
        break;
    }

    if (convertedValue) {
      return convertedValue.value
    }

    return value;
  }

  public roundDensity(value, toUnit: string) {
    if(toUnit != API_UNITS.Density) {
      return OB.round(value, 10);
    }

    return value;
  }

  public convertObject(colSettings: Array<FieldLevelUoMSettings>, obj: any): void {
    for (const col of colSettings) {
      
      if(obj.hasOwnProperty(col.colName)) {
        let prop = obj[col.colName];
        if (OB.isObject(prop)) {
          if(prop.hasOwnProperty("value")) prop.value = this.convertValue(col, prop.value);
          if(prop.hasOwnProperty("uom")) prop.uom = col.currentUnit;
        } else {
          obj[col.colName] = this.convertValue(col, prop);
        }
      }
    }
  }

  public revertValue(colSetting: FieldLevelUoMSettings, value: number) {
    if (colSetting == undefined) {
      return;
    }

    const revertColumn: FieldLevelUoMSettings = {
      ...colSetting
    };
    revertColumn.currentUnit = colSetting.unit;
    revertColumn.unit = colSetting.currentUnit;
    return this.convertValue(revertColumn, value);
  }

  public revertObject(colSettings: Array<FieldLevelUoMSettings>, obj: any): void {
    for (const col of colSettings) {
      
      if(obj.hasOwnProperty(col.colName)) {
        let prop = obj[col.colName];
        if (OB.isObject(prop)) {
          if(prop.hasOwnProperty("value")) prop.value = this.revertValue(col, prop.value);
          if(prop.hasOwnProperty("uom")) prop.uom = col.unit;
        } else {
          obj[col.colName] = this.revertValue(col, prop);
        }
      }
    }
  }

}
