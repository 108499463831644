<div class="container m-0">
  <div class="container-fluid card-body">
  <!-- title-->
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name">{{'admin.userRoles'|translate}}</div>
    </div>
  </div>

  <!-- start-->
  <div class="content-body" *ngIf="isReady">
    <app-table-toolbar
      [event$] = "toolbarEvent$"
      [disabledCatalog]="disabledCatalog"
      [columns] ="columns"
      [data]="tableView"
      [sharing]="toolbarSharing"
      [topRowForm] = "userRoleForm"
      [addText] = "'Grant'"
      [catalog]="catalog"
      (onSelectCatalog) ="handleSelectCatlog($event)"
      (onReset) = "handleReset()"
      (onAdd) ="handleAdd($event)"
      (onEdit)="handleEdit($event)"
      (onDelete)="handleDelete($event)">
    </app-table-toolbar>
  </div>
</div>
</div>