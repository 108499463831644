import { DateTimeService } from './../core/services/date-time.services';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { JobService } from '../job/job.service';
import { Jobs } from '../shared/models/jobs.model';
import { JobDashboard } from './../shared/models/jobs.model';
import * as LS from 'src/app/utils/localstorage';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../core/services/settings.service';
import { UnitSystemsService } from '../core/services/unitsystems.service';
import { DATE_TIME_FORMAT, MODAL_IDENTIFIERS } from '../shared/enums/enums';
import { DialogInput, DialogResult } from '../shared/components/dialog/dialog.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DialogModalService } from '../core/services/dialogModal.service';
import { environment } from 'src/environments/environment';

export enum JOB_CHOICES {
  NEW = "new",
  CLONE = "clone"
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public gridJobs: any;
  public listJobs: any;
  public listRecentJobs: any;
  public rtJobs: any = [];
  public jobCount: number;
  public headers: any;
  public jobActivities: string[] = ['Drilling', 'Tripping', 'Circulating', 'Planning'];
  public alertedJobs: Jobs[];
  public jobsByType: any = [[], [], [], []];
  public viewGridMode = false;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public jobDetails: any;
  public acChanged: any = '';
  public interval: any;
  public acTimeout: any;
  public pageLoaded = false;
  public pageSize = 25;
  public dialogInput: DialogInput = {
    data: {
      body: ""
    },
    buttons: {
      cancel: false,
      ok: true
    }
  };
  public createJobDialogInput: DialogInput = {
    data: {
      header: "New job",
      options: [
        {
          label: "Create new Job and new Well",
          value: JOB_CHOICES.NEW
        },
        {
          label: "Copy an existing Job",
          value: JOB_CHOICES.CLONE
        }
      ]
    },
    buttons: {
      cancel: true,
      ok: false,
      next: true
    }
  };

  public toggleModel = true;
  public rootJobsData: any[];
  public listJobIds: string[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _job: JobService,
    public _settings: SettingsService,
    public _dialogModal: DialogModalService,

    public _ngxSmartModal: NgxSmartModalService
  ) {
  }

  ngOnInit() {
    this._utilities.showSpinner(true);
    this.viewGridMode = false;
    this.loadDashboardData();
    this.headers = [
      { title: 'job.name', uom: false, key: "jobName", type: 'text' },
      { title: 'job.jobNumber', uom: false, key: "jobNumber", type: 'text' },
      { title: 'job.jobType', uom: false, key: "jobTypes", type: 'array' },
      { title: 'rig.name', uom: false, key: "rigName", type: 'text' },
      { title: 'well.name', uom: false, key: "wellName", type: 'text' },
      { title: 'spudDate', uom: false, key: "spudDate", type: 'text' },
      { title: 'job.createdBy', uom: false, key: "jobOwner", type: 'text' },
      { title: 'job.status', uom: false, key: "jobStatus", type: 'text' },
      { title: 'region', uom: false, key: "region", type: 'text' },
      { title: 'country', uom: false, key: "country", type: 'text' },
      { title: '', uom: false, icon: true, key: "editIcon" }
    ];
  
  }

  ngAfterViewInit() {
    setTimeout(f=>{this.notifyJobAuthentication()}, 0);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private notifyJobAuthentication() {
    if(this._utilities.jobAuthenticationInfo){
      console.log("JobAuthen", this._utilities.jobAuthenticationInfo?.message);
      this.dialogInput.data.body = this._utilities.jobAuthenticationInfo?.message;
      this._dialogModal.showInfo(this.dialogInput);
      this._utilities.jobAuthenticationInfo = null;
    }
  }

  public loadDashboardData() {
    let promises = [];
    promises.push(this._job.getJobsAsync());
    Promise.all(promises)
      .then((data) => {
        if (data && !(data[0] instanceof HttpErrorResponse)) {
          let jobList = [];
          jobList = this.convertData(data[0]);
          jobList = jobList.filter(
            (x) =>
              x.jobStatus !== null &&
              (x.jobStatus.toLowerCase() == 'active' ||
                x.jobStatus.toLowerCase() == 'inactive' ||
                x.jobStatus.toLowerCase() == 'complete')
          );
          jobList.sort((a, b) =>
            a ? a.jobName.localeCompare(b.jobName) : -1
          );
          this.listJobs = jobList;
          this.jobCount = this.listJobs.length;
          this.rootJobsData = [...this.listJobs];

          //Keep toggle job when navigate back
          const isShowAll = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllJobStatus);
          this.toggleModel = (isShowAll !== null && isShowAll !== undefined) ?  isShowAll : true;
          this.toggleChanged();
        }
        this._utilities.showSpinner(false);
        this.pageLoaded = true;
      })
      .catch((err) => {
        return err;
      });
  }

  openAddJobDashboardModal() {
    if(environment.local) {
      this.router.navigate(['/jobs/newjob/jobprofile']);
      return;
    }
    
    this._dialogModal.showConfirmation(this.createJobDialogInput).subscribe(res => {
      if (res.action && res.action === DialogResult.Ok) {
        switch(res.value) {
          case JOB_CHOICES.NEW:
            this.router.navigate(['/jobs/newjob/jobprofile']);
            break;
          case JOB_CHOICES.CLONE:
            this.router.navigate(['/jobs/newjob/clone']);
            console.log("open up",res.value);
        }
      }
    });
}

  switchView(val: boolean) {
    this.viewGridMode = val;
    if (this.viewGridMode) {
      this.jobCount = this.gridJobs.length;
    }
    else {
      this.jobCount = this.listJobs.length;
    }
  }

  public confirmDialog() {
    
  }

  public cardClick(job) {
    if (job && job.tableCellSelected === 'editIcon') {
      this.router.navigate([`/job/${job.jobId}/jobprofile`]);
    }
  }

  public convertData(jobs: JobDashboard[]): JobDashboard[] {
    if (jobs && jobs.length > 0) {
      for (let job of jobs) {
        job.spudDate = job.spudDate ? this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG) : ''
      }
      return jobs;
    }
    return [];
  }

  public toggleChanged() {
    LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllJobStatus, this.toggleModel);
    if(!this.toggleModel) {
      this._settings.getLastVisitedJob().subscribe(arr => {
        this.listJobIds = [];
        this.listJobs = [];
        this.jobCount = 0;
        if(arr && arr.length > 0) {
          this.listJobIds = [...arr];
          this.getRecentJobByIds(this.listJobIds);
          this.jobCount = this.listJobs.length;
        }
      });
    } else {
      this.listJobs = [...this.rootJobsData];
      this.jobCount = this.listJobs.length;
    }
  }

  private getRecentJobByIds(jobs) {
    jobs.forEach(id => {
      const jobId = this.rootJobsData.find(e => e.jobId === id);
      if (jobId) this.listJobs.push(jobId);
    });
  }

}
