
// export const getTokenKeyLocalStorage = () => {
// 	let tokenKey = null;
// 	Object.keys(localStorage).forEach(key => {
// 		if (key.startsWith('{"authority":')) {
// 			if (key.includes('api:')) {
// 				tokenKey = key;
// 			}
// 		}
// 	});
// 	return tokenKey;
// }
// export const getTokenDataLocalStorage = () => {
// 	let value = localStorage.getItem(getTokenKeyLocalStorage());
// 	return value;
// }

// LocalStorage

export const getLocalStorage = (key: string) => {
	if (key) {
		var data = localStorage.getItem(key);
		try {
			return JSON.parse(data);

		} catch (error) {
			return null;
		}
	}
	return null;
};

export const setLocalStorage = (key: string, data: any) => {
	if (key) {
		if (typeof data === 'string' || data instanceof String) {
			localStorage.setItem(key, String(data));
		}
		else {
			localStorage.setItem(key, JSON.stringify(data));
		}
	}
};

export const setPropertyLocalStorage = (key: string, data: any, property: string) => {
	const cached = getLocalStorage(key);
	if (cached) {
		cached[property] = data;
		setLocalStorage(key, cached);
	}
}

export const removePropertyLocalStorage = (key: string, property: string) => {
	const cached = getLocalStorage(key);
	if (cached) {
		delete cached[property];
		setLocalStorage(key, cached);
	}
}

export const getLocalStorageString = (key: string) => {
	if (key) {
		return localStorage.getItem(key)
	}
	return null;
};

export const setLocalStorageString = (key: string, data: string) => {
	if (key) {
		localStorage.setItem(key, data);
	}
};

export const removeLocalStorage = (key: string) => {
	if (key) {
		localStorage.removeItem(key);
	}
};

export const clearLocalStorage = () => {
	localStorage.clear();
};

export const importLocalStorage = (input: string) => {
	localStorage.clear();
	var data = JSON.parse(input);
	Object.keys(data).forEach(function (k) {
		localStorage.setItem(k, data[k]);
	});
	let all = (JSON.stringify(localStorage));
	let size = sizeofLocalStorage();
};

export const exportLocalStorage = (filename: string) => {
	let all = (JSON.stringify(localStorage));
	let size = sizeofLocalStorage();
};

export const sizeofLocalStorage = () => {
	var _lsTotal: number = 0;
	var _xLen: number;
	var key: string;
	for (key in localStorage) {
		if (!localStorage.hasOwnProperty(key)) {
			continue;
		}
		_xLen = ((localStorage[key].length + key.length) * 2);
		_lsTotal += _xLen;
		console.log(key.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
	};
	let totalKb = (_lsTotal / 1024).toFixed(2);
	console.log("Total = " + totalKb + " KB");
	return totalKb;
}

// SessionStorage

export const getSessionStorage = (key: string): any => {
	return sessionStorage.getItem(key);
}

export const setSessionStorage = (key: string, data: any) => {
	if (key) {
		if (typeof data === 'string' || data instanceof String) {
			sessionStorage.setItem(key, String(data));
		}
		else {
			sessionStorage.setItem(key, JSON.stringify(data));
		}
	}
}

export const getSessionStorageString = (key: string) => {
	if (key) {
		return sessionStorage.getItem(key)
	}
	return null;
};

export const setSessionStorageString = (key: string, data: string) => {
	if (key) {
		sessionStorage.setItem(key, data);
	}
};

export const removeSessionStorage = (key: string) => {
	if (key) {
		sessionStorage.removeItem(key);
	}
};

export const clearSessionStorage = () => {
	sessionStorage.clear();
};

// clear msal token storage
export const clearMsalTokenStorage = () => {
	var prefix = "msal";
	Object.keys(sessionStorage).forEach(key => {
		if (key.startsWith('msal') ||
			key.startsWith('{"authority":'))
			sessionStorage.removeItem(key);
	});
	Object.keys(localStorage).forEach(key => {
		if (key.startsWith('msal') ||
			key.startsWith('{"authority":'))
			localStorage.removeItem(key);
	});
}

// clear local storage except token and catalogs
export const clearJobLocalStorage = () => {
	Object.keys(localStorage).forEach(key => {
		if (key != LOCAL_STORAGE_KEY.Data.Job.JobId &&
			!key.includes('token') &&
			!key.startsWith('msal') &&
			!key.startsWith('{"authority":') &&
			!key.startsWith(LOCAL_STORAGE_KEY.User.Prefix) &&
			!key.startsWith(LOCAL_STORAGE_KEY.Catalogs.Prefix))
			localStorage.removeItem(key);
	});
}

// clear local storage except token and catalogs
export const clearCatalogLocalStorage = () => {
	clearPrefixLocalStorage(LOCAL_STORAGE_KEY.Catalogs.Prefix);
}

// clear local storage except token and catalogs
export const clearPrefixLocalStorage = (prefix: string) => {
	Object.keys(localStorage).forEach(key => {
		if (key.startsWith(prefix)) localStorage.removeItem(key);
	});
}

export const LOCAL_STORAGE_KEY = {
	RedirectUrl: "redirectUrl",
	LANG: 'lang',
	UNDERSCORE: '_',
	Token: {
		Gen: "local.token",
		GenExpire: "local.token_Expire",
		GenUsername: "local.token_Username",
		AD: "msal.idtoken",
	},
	Catalogs: {
		Prefix: "catalog_",
		Expire: "catalog_Expire",
		FluidProperties: {
			AdditionalFluidProperties: "catalog_AdditionalFluidProperties",
			Brines: 'catalog_Brines',
			CalculationBrines: 'catalog_CalculationBrines',
			BaseFluidTypes: 'catalog_BaseFluidTypes',
			CalculationBaseFluidTypes: 'catalog_CalculationBaseFluidTypes',
			FluidSet: 'catalog_FluidSets',
			FluidSystems: 'catalog_FluidSystems',
			FluidLocations: 'catalog_FluidLocations'
		},
		Geo: {
			RegionsCountries: "catalog_RegionsCountries",
			Regions: "catalog_Regions",
			Countries: "catalog_Countries",
			TimeZones: "catalog_WellTimeZones",
		},
		Job: {
			Type: "catalog_JobType",
			Purpose: "catalog_JobPurpose",
			Status: "catalog_JobStatus",
			CustomerType: "catalog_JobCustomerType",
			Contractors: "catalog_Contractors",
			Operators: "catalog_Operators",
		},
		Well: {
			Basins: "catalog_WellBasins",
			Countries: "catalog_WellCountries",
			Operators: "catalog_WellOperators",
			Purposes: "catalog_WellPurposes",
			Statuses: "catalog_WellStatuses",
			Types: "catalog_WellTypes",
			LithoTypes: "catalog_LithoTypes"
		},
		Tubular: {
			ComponentTypes: "catalog_TubularComponentTypes",
			CasingLinerType: "catalog_TubularCasingLinerType",
		},
		UnitSystem: {
			UnitSystemName: "catalog_UnitSystemName",
			QuantityUnits: "catalog_QuantityUnits",
		},
		Rig: {
			PitTypes: "catalog_PitTypes",
			SurfaceEquipmentType: "catalog_SurfaceEquipmentType",
			SurfaceEquipmentTypeNames: "catalog_SurfaceEquipmentTypeNames",
			Pumps: "catalog_Pumps",
			SolidsControlEquipment: "catalog_SolidsControlEquipment",
			Casings: "catalog_Casings",
			Risers: "catalog_Risers",
			Lithos: "catalog_Lithos",
			Pumps_Properties: "catalog_Pumps_Properties",
			Pumps_Manufacturers: "catalog_Pumps_Manufacturers",
			Pumps_Models: "catalog_Pumps_Models",
			Pumps_Types: "catalog_Pumps_Types",
			Pump_Positions: "catalog_Pump_Positions",
			Solids_Properties: "catalog_Solids_Properties",
			Solids_Manufacturers: "catalog_Solids_Manufacturers",
			Solids_Models: "catalog_Solids_Models",
			Solids_Styles: "catalog_Solids_Styles",
			Solids_Screens: "catalog_Solids_Screens",
		},
		Analysis: {
			Type:"catalog_AnalysisTypes",
			Status:"catalog_AnalysisStatus",
			Reason:"catalog_AnalysisReason"
		},
		AnalysisCase: {
			Reason:"catalog_AnalysisCaseReason"
		}
	},
	Data: {
		Prefix: "data_",
		GeoThermal: 'data_GeoThermal',
		PorePressures: 'data_PorePressures',
		PorePressure: 'data_PorePressure',
		Fracture: 'data_fracture',
		Fractures: 'data_fractures',
		Job: {
			JobId: "JobId",
			JobDetails: "data_JobDetails",
			Jobs: "data_jobs",
			SalesDetails: "data_SalesInformation",
			CreateNewJob: "data_NewJob"
		},
		Rig: {
			Prefix: "data_Rig_",
			Packet: "data_Rig_Packet",
			Rig: "data_Rig_Details",
			MudLiftPump: "data_Rig_MudLiftPump",
			Pits: "data_Rig_Pits",
			Pit: "data_Rig_Pit_Input",
			Pumps: "data_Rig_Pumps",
			ServiceLine: "data_Rig_ServiceLine",
			SurfaceEquipment: "data_Rig_SurfaceEquipment",
			SolidsControlEquipment: "data_Rig_SolidsControlEquipment",
		},
		Well: {
			WellHeader: "data_WellHeader",
			CreateNewWell: 'data_NewWell'
		},
		CatalogSetup: {
			CreateUpdate: "data_CatalogCreateUpdate"
		}
	},
	User: {
		ActivePage: "activepage",
		online: 'user_isOnline',
		Prefix: "user_",
		Theme: 'user_theme',
		Settings: "user_settings",
		RecentJobs: "user_recentjobs",
		ShowAllJobStatus: "user_ShowAllJobStatus"
	},
	ANALYSIS: 'data_analysis',
	ANALYSIS_SETUP: 'data_analysis_setup',
	ANALYSIS_CASE_SETUP: 'data_analysis_case_setup',
	ANALYSIS_CASE_SETUP_RESULT: 'data_analysis_case_setup_result',
	ANALYSIS_DETAIL: 'data_analysis_detail',
	ANALYSIS_CASE: 'data_analysis_case',
	ANALYSIS_CASE_DETAIL: 'data_analysis_case_detail',
	FLUID_SET_PREFIX: 'data_FluidSet',
	INTERVAL_PREFIX: 'data_Interval',
	ADDITIONAL_PROPERTIES: 'data_Interval_Additional_Properties',
	ADDITIONAL_PROPERTY: 'data_Interval_Additional_Property',
	BHA_Run: 'data_BHA_Run',
	BIT_RUN: 'data_Bit_Run',
	CASING: 'data_Casing',
	CASINGS: 'data_Casings',
	FLUID_SET: 'data_FluidSet',
	FluidSets: 'data_FluidSets',
	GENERAL_PROPERTIES: 'data_Interval_General_Properties',
	HYDRAULICS_REQUEST: 'data_HydraulicsRequest',
	HYDRAULICS_RESULTS: 'data_HydraulicsResult',
	HYDRAULICS_TRANSFORM: 'data_HydraulicsTransform',
	INTERVAL_DETAIL: 'data_IntervalDetail',
	INTERVAL: 'data_Interval',
	INTERVALS: 'data_Intervals',
	INTERVAL_PACKET: 'data_Interval_Packet',
	Interval_Editing: "data_Interval_Editing",
	Lithography: 'data_Lithography',
	LithoStation: 'data_LithoStation',
	Lithostratigraphy_Stations: 'data_Lithostratigraphy_Stations',
	Lithostratigraphy: 'data_Lithostratigraphy',
	NAF_PROPERTIES: 'data_Interval_Naf_Properties',
	Openholes: 'data_Openholes',
	OPERATING_PARAMETERS: 'data_operating_parameters',
	Riser: 'data_Riser',
	Risers: 'data_Risers',
	TARGET_SET: 'data_Interval_TargetSet',
	TARGET_SETS: 'data_Interval_TargetSets',
	Trajectory: 'data_Trajectory',
	TrajectoryStation: 'data_trajectoryStations',
	TUBULARS_COMPONENT: 'data_TubularComponent',
	Tubulars: 'data_Tubulars',
	WBM_PROPERTIES: 'data_Interval_Wbm_Properties',
	WELLBORES: 'data_Wellbores',
};
