import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, NoPreloading } from '@angular/router';
import { CreateJobComponent } from './create-job/create-job.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ResolveRoutesComponent } from './resolve-routes/resolve-routes.component';
import { SettingsComponent } from './settings/settings.component';
import { LogoutComponent } from './logout/logout.component';
import { CreateWellComponent } from './create-job/create-well/create-well.component';

import { MsalGuard } from '@azure/msal-angular';
import { LocalTokenGuard } from './core/guards/localtoken.guard';
import { CurrentUnitResolver } from './core/guards/resolve-unit.resolver';
import { PageHasErrorsGuard } from 'src/app/core/guards/page-has-errors.guard';
import { JobCatalogsResolver } from 'src/app/core/guards/resolve-jobcatalogs.resolver';
import { LocalGuard } from 'src/app/core/guards/local.guard';
import { WellHeaderCatalogsResolver } from 'src/app/core/guards/resolve-wellheadercatalogs.resolver';

import { StyleGuideComponent } from './style-guide/style-guide.component';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { PAGES, ROLES } from './shared/enums/enums';
import { JobsTransferComponent } from './jobs-transfer/jobs-transfer.component';
import { CatalogsTransferComponent } from './catalogs-transfer/catalogs-transfer.component';
import { LocalInternetGuard } from './core/guards/local-internet.guard';
import { LocalNetworkGuard } from './core/guards/local-network.guard';
import { CatalogsManagementComponent } from './catalogs-management/catalogs-management.component';
import { CatalogSetupComponent } from './catalogs-management/catalog-setup/catalog-setup.component';
import { CatalogImportComponent } from './catalogs-management/catalog-import/catalog-import.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { RolesComponent } from './roles/roles.component';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { BaseOilChartComponent } from './job/base-oil-chart/base-oil-chart.component';
import { FindExistingJobCloneComponent } from './job/find-existing-job-clone/find-existing-job-clone.component';
import { CloudGuard } from './core/guards/cloud.guard';

const isIframe = window !== window.parent && !window.opener;
export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: NoPreloading,
  onSameUrlNavigation: 'reload',
  enableTracing: true,
  // Don't perform initial navigation in iframes
  initialNavigation: !isIframe ? 'enabled' : 'disabled'
};

environment.auth.guard = environment.local ? LocalTokenGuard : MsalGuard;
environment.web.isCloud = !environment.local;
const authGuard: any = environment.auth.guard;
console.log('auth.guard app-routing.module', environment.local ? "LocalTokenGuard" : "MsalGuard");

const routes: Routes = [
  {
    path: '',
    redirectTo: PAGES.JobsDashboard,
    pathMatch: 'full'
  },
  {
    path: PAGES.JobsDashboard,
    component: DashboardComponent,
    canActivate: [authGuard],
    resolve: { currentUnit: CurrentUnitResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: PAGES.CatalogsManagement,
    component: CatalogsManagementComponent,
    data: {
      roles: [ROLES.Admin],
    },
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: PAGES.UserRoles,
    component: UserRolesComponent,
    data: {
      roles: [ROLES.Admin],
    },
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: PAGES.Roles,
    data: {
      roles: [ROLES.Admin],
    },
    component: RolesComponent,
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: PAGES.JobsTransfer,
    component: JobsTransferComponent,
    canActivate: [LocalGuard, LocalNetworkGuard],
    resolve: { currentUnit: CurrentUnitResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: PAGES.CatalogsTransfer,
    component: CatalogsTransferComponent,
    canActivate: [LocalGuard, LocalNetworkGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'job/:jobId/analysis',
    loadChildren: () => import('./job/analysis/analysis.module').then(m => m.AnalysisModule)
  },
  {
    path: 'job/:jobId',
    loadChildren: () => import('./job/job.module').then(m => m.JobModule)
  },
  {
    path: 'jobs/newjob/jobprofile',
    component: CreateJobComponent,
    canActivate: [authGuard],
    canDeactivate: [PageHasErrorsGuard],
    resolve: { currentUnit: CurrentUnitResolver, jobCatalogs: JobCatalogsResolver },
  },
  {
    path: 'jobs/newjob/clone',
    component: FindExistingJobCloneComponent,
    canActivate: [authGuard, CloudGuard],
    resolve: { currentUnit: CurrentUnitResolver, jobCatalogs: JobCatalogsResolver },
  },
  {
    path: 'jobs/newjob/wellheader',
    component: CreateWellComponent,
    canActivate: [authGuard],
    canDeactivate: [PageHasErrorsGuard],
    resolve: { currentUnit: CurrentUnitResolver, wellCatalogs: WellHeaderCatalogsResolver },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [authGuard],
    resolve: { currentUnit: CurrentUnitResolver },
  },
  
  { path: "resolveRoutes", component: ResolveRoutesComponent, },
  { path: 'logout', component: LogoutComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'style-guide', component: StyleGuideComponent },
  // { path: 'expand/:name', loadChildren: './expand-table/expand-table.module#ExpandTableModule' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration), SharedModule],
  declarations: [],
  exports: [RouterModule, SharedModule],
  providers: [LocalGuard, CloudGuard, LocalInternetGuard, LocalNetworkGuard]
})

export class AppRoutingModule { ; }
export const routingRootComponents = [
  SettingsComponent,
  DashboardComponent,
  CatalogsManagementComponent,
  CatalogSetupComponent,
  CatalogImportComponent,
  BaseOilChartComponent,
  UserRolesComponent,
  RolesComponent,
  CreateJobComponent,
  FindExistingJobCloneComponent,
  CreateWellComponent,
  StyleGuideComponent,
  PageNotFoundComponent,
];