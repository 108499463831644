import { PAGINATION_PROPERTIES } from './../../utils/constants';
import { JobDashboard } from './../../shared/models/jobs.model';
import { ColumnSettingsInfo } from 'src/app/core/services/columnSettings';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
  @Input() data: any;
  @Input() subHeaders: any;
  @Input() headers: any;
  @Input() dataSource: any;
  @Input() jobs: JobDashboard[]
  @Input() enterCustom: boolean = false;
  @Input() download: boolean = false;
  @Input() rowPointer: boolean = true
  @Input() pageSize = PAGINATION_PROPERTIES.ITEMS_PER_PAGE
  @Input() maxColumnDisplayLength: number = 0;
  @Input() showToolTips: number = 0;
  @Output() row = new EventEmitter<any>();
  colSettings = ColumnSettingsInfo.jobDashboard

  public customRow: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    /*this.data.map(x => {
      // if (this.dataSource.wellName.toLowerCase() == x.wellName) {
      switch (this.dataSource.AdasVariableName) {
        case 'MD':
          x.measureDepth = this.dataSource.Value;
          x.measureDepthUoM = this.dataSource.Uom;
          this.refreshData();
          break;
        default:
          break;
        //  }
      }
    });*/
  }

  refreshData() {
    this.data = this.data.slice();
  }

  clickRow($event) {
    this.row.emit($event);
  }
}
