<div class="table-toolbar-container">
  <div *ngIf="showEmptyPage">
    <label>{{'noDataFound'|translate}}</label>
  </div>
  <div *ngIf="!showEmptyPage" [ngClass]="widthOfSection!=='col-md-12' ? 'row' :''">
    <div [ngClass]="widthOfSection!=='col-md-12'? widthOfSection:''" class="px-0">
      <div class="table-container">
        <app-filter-popup
          *ngIf = "showFilterPopup"
          [options] ="filterOptions"
          [tableView]="tableView"
          (onCheckField)="handleOnCheckField($event)"
          (onSort)="handleOnSort($event)"
          (onClose)="closeFilter()"
        ></app-filter-popup>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <table class="table" [ngClass] = "hasMaxHeight? 'table-fixed': 'table'">
            <thead>
              <!-- header row-->
              <tr>
                <ng-container *ngIf="showCatalogButton">
                  <th class="cellpad catalog-icon"></th>
                </ng-container>
                <ng-container *ngIf="hasDrop">
                  <td class="grid-vertical-dummy">
                  </td>
                </ng-container>
                <ng-container *ngIf="hasWarning">
                  <th class="cellpad cell-space-icon"></th>
                </ng-container>
                <ng-container *ngFor="let item of columnsView; let i = index; let l = last; trackBy: trackByFn">
                  <th scope="col" [ngStyle]="{'min-width': item.width?item.width: null}">
                    <span *ngIf="filter" class ="pointer" [id]="'header-' + item.key" (click)="openFilter($event, item)">
                      {{item.i18n | translate}}
                      <app-uom-label 
                        *ngIf="isShowUomLabel(item)" 
                        [columnSettings]="colSettings"
                        [fieldIdentifier]="item.key">
                      </app-uom-label>
                      <span [ngClass] ="isFiltering(item.key) ? 'ic-filter filter-color-orange': 'ic-filter'"></span>
                    </span>
                    <span *ngIf ="!filter" [ngClass]="i === 0 ? 'd-flex justify-content-center' : ''">
                      {{item.i18n | translate}}
                      <span *ngIf="validatorData.invalid && !showTopRow && i === 0" class="ic-2alert-yellow warning-icon" appToolTip [tooltip]="validatorData.message"></span>
                      <app-uom-label 
                        *ngIf="isShowUomLabel(item)" 
                        [columnSettings]="colSettings"
                        [fieldIdentifier]="item.key">
                      </app-uom-label>
                    </span>
                  </th>
                </ng-container>
              </tr>

              <!-- input row -->
              <tr [formGroup]="topRowForm" *ngIf="showTopRow">
                <ng-container *ngIf="showCatalogButton">
                  <td class="catalog-icon">
                    <span [ngClass]="disabledCatalog ? 'disabled-btn' : ''" class="filter-color-orange icon-catalog icon-btn"
                      (click)="toggleCatalog()"> </span>
                  </td>
                </ng-container>
                <ng-container *ngIf="hasDrop">
                  <td class="grid-vertical-dummy">
                  </td>
                </ng-container>
                <ng-container *ngIf="hasWarning">
                  <td class="cell-space-icon">
                  </td>
                </ng-container>
                <td *ngFor="let column of columnsView; let idx = index; trackBy: trackByFn">
                  <div *ngIf="!column.hideInput" class="td-inner">
                    <ng-container [ngSwitch]="column.fieldType">
                      <input
                        *ngSwitchCase="FIELD_TYPE.TEXT"
                        formControlName={{column.key}}
                        type="text"
                        class="input-field"
                        [maxlength] = column.maxlength
                        [readonly]="column.readonly"
                        [attr.disabled]="column.readonly ? '' : null"
                        [ngClass]="{ 'input-danger': topRowForm?.get(column.key)?.invalid && submitted }"/>
                      <select
                        *ngSwitchCase="FIELD_TYPE.DROPDOWN"
                        formControlName={{column.key}}
                        class="select-dropdown"
                        [readonly]="column.readonly"
                        [attr.disabled]="column.readonly ? '' : null"
                        [ngClass]="{ 'input-danger': topRowForm?.get(column.key)?.invalid && submitted }">
                        <option class="option-dropdown" *ngFor="let item of column.data" [disabled]="item?.disabled? true:null" value="{{item?.value || item}}">
                          {{item?.label || item}}
                        </option>
                      </select>
                      <input
                        *ngSwitchCase="FIELD_TYPE.NUMBER"
                        formControlName={{column.key}}
                        type="number"
                        class="input-field"
                        step="any"
                        appUomPrecision
                        [fixedDecimals]="colSettings | decPrecision:column.key"
                        [pageLoaded]="true"
                        [readonly]="column.readonly"
                        [attr.disabled]="column.readonly ? '' : null"
                        [attr.data-bk]
                        [attr.data-full-number]=""
                        [ngClass]="{ 'input-danger': topRowForm?.get(column.key)?.invalid && submitted }"
                      />
                      <ng-container *ngSwitchCase="FIELD_TYPE.CHECKBOX">
                        <label class="checkbox-label checkbox-field" *ngIf="topRowForm?.get(column.key)">
                          <input
                            formControlName={{column.key}}
                            type="checkbox"
                            name="selectRow"
                            [readonly]="column.readonly"
                            [attr.disabled]="column.readonly ? '' : null"
                            [checked]="false"/>
                          <div class="checkbox-mark checkbox-style"></div>
                        </label>
                      </ng-container>
                    </ng-container>
                    <app-error-message [submitted]="submitted" [controlName]="topRowForm?.get(column.key)"></app-error-message>
                  </div>
                </td>
                <td>
                  <div class="action-inner">
                    <span class="filter-color-orange ic-close-icon icon-btn" (click)="goReset()"> </span>
                    <span *ngIf="hasConfigEvent && !hideConfigBtn" class="icon-btn" style="cursor: initial;"> </span>
                    <span *ngIf="showEditButton" class="icon-btn" style="cursor: initial;"></span>
                  </div>
                </td>
              </tr>

              <!-- actions row -->
              <tr *ngIf="showTopRow">
                <td *ngIf="showToolbar" colspan="20">
                  <span *ngIf="validatorData.invalid" class="ic-2alert-yellow warning-icon mr-2" appToolTip [tooltip]="validatorData.message"></span>
                  <span *ngIf="showAddButton" class="link-primary mr-4" (click)="goAdd($event)">
                    <span class="icon-unit-systems-add-icon mr-2"></span>{{addText|translate}}
                  </span>
                  <span *ngIf="showDeleteAllButton" class="link-primary mr-4" (click)="goDeletingAll($event)">
                    {{deleteAllText|translate}}
                  </span>
                </td>
              </tr>

              <!-- catalog row -->
              <tr *ngIf="showCatalogTable">
                <td colspan="12">
                  <div class="table-catalog mb-3">
                    <div class="col-12">
                      <app-h-table [hidden]="false" [tableName]="" [headers]="catalog.header"
                        [hidden]="!showCatalogTable" [enterCustom]="false" (row)="selectCatalog($event);"
                        [colSettings]="colSettings" [data]="catalog.data">
                      </app-h-table>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody [ngStyle]="{'max-height': maxHeight}">
              <ng-container *ngFor="let item of currentTableView; let i = index">
                <tr cdkDragBoundary="tbody" cdkDrag [cdkDragDisabled]="!hasDrop || !allowDrag" cdkDragLockAxis="y" class="tr-child" [ngClass]="dropHighlightClass">
                  <ng-container *ngIf="showCatalogButton">
                    <td class="catalog-icon"></td>
                  </ng-container>

                  <ng-container *ngIf="hasDrop">
                    <td class="grid-vertical">
                      <img src="../../../../assets/images/icons/ic-grip-vertical.svg">
                    </td>
                  </ng-container>
                  
                  <ng-container *ngIf="hasWarning">
                    <td class="cell-space-icon">
                      <span *ngIf="warningStatus(item)" 
                      class="ic-2alert-yellow warning-icon"
                      appToolTip [tooltip]="warningMess(item)"></span>
                    </td>
                  </ng-container>

                  <td *ngFor="let column of columnsView; let idx = index; trackBy: trackByFn">                  
                    <div [ngClass]="dragThemeClass" class="td-inner">
                      <ng-container [ngSwitch]="column.fieldType">
                        <input
                          *ngSwitchCase="FIELD_TYPE.TEXT"
                          type="text"
                          class="input-field"
                          [value]="getValueOfObject(item, column.key, column.fieldType)"
                          [attr.readonly]="true"
                          [attr.disabled]="true"
                        />
                        <input
                          *ngSwitchCase="FIELD_TYPE.DROPDOWN"
                          type="text"
                          class="input-field"
                          [value]="getDropdownLabel(item, column.key)"
                          [attr.readonly]="true"
                          [attr.disabled]="true"
                      />
                        <input
                          *ngSwitchCase="FIELD_TYPE.NUMBER"
                          type="number"
                          class="input-field"
                          step="any"
                          [value]="getValueOfObject(item, column.key, column.fieldType)"
                          [attr.readonly]="true"
                          [attr.disabled]="true"
                          appUomPrecision
                          [fixedDecimals]="colSettings | decPrecision:column.key"
                          [pageLoaded]="true"
                          [attr.data-bk]
                          [attr.data-full-number]="item.value"
                        />
                        <ng-container *ngSwitchCase="FIELD_TYPE.CHECKBOX">
                          <label class="checkbox-label checkbox-field">
                            <input type="checkbox"
                            name="selectRow"
                            (change)="selectRowChange($event, column, i, item)"
                            [checked]="getValueOfObject(item, column.key, column.fieldType)"
                            [attr.readonly]="column.editTable == true ? null: true"
                            [attr.disabled]="column.editTable == true ? null: true" />
                            <div class="checkbox-mark checkbox-style" [ngClass] = "column.editTable == true?'':'checkbox-disabled'"></div>
                          </label>
                        </ng-container>
                      </ng-container>
                    </div>
                  </td>

                  <!-- Handle show/hide buttons-->
                  <td *ngIf="showToolbar">
                    <div class="action-inner" *ngIf="indexEditingView === i; else enableBtn">
                      <ng-container *ngIf="hasConfigEvent && !hideConfigBtn">
                        <span class="filter-color-orange ic-gear-icon icon-btn disabled-btn"> </span>
                      </ng-container>
                      <ng-container *ngIf="showEditButton">
                        <span class="filter-color-orange ic-edit-icon icon-btn disabled-btn"> </span>
                      </ng-container>
                      <ng-container *ngIf="showDeleteButton">
                        <span class="filter-color-orange ic-close-icon icon-btn disabled-btn"> </span>
                      </ng-container>
                    </div>
                    
                    <ng-template #enableBtn>
                      <div class="action-inner">
                        <ng-container *ngIf="hasConfigEvent && !hideConfigBtn">
                          <span class="filter-color-orange ic-gear-icon icon-btn" (click)="goConfiguring(i, item)"> </span>
                        </ng-container>
                    
                        <ng-container *ngIf="showEditButton">
                          <span *ngIf="disabledEditBtn(item)"  class="filter-color-orange ic-edit-icon icon-btn disabled-btn"> </span>
                          <span *ngIf="!disabledEditBtn(item)"  class="filter-color-orange ic-edit-icon icon-btn" (click)="goEdit(i, item)"> </span>
                        </ng-container>
                    
                        <ng-container *ngIf="showDeleteButton">
                          <span *ngIf="isEditing || disabledDeleteBtn(item)" class="filter-color-orange ic-close-icon icon-btn disabled-btn"> </span>
                          <span *ngIf="!(isEditing || disabledDeleteBtn(item))" class="filter-color-orange ic-close-icon icon-btn" (click)="goDeleting(i, item)"> </span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <pagination *ngIf="pagination" [boundaryLinks]="true" [directionLinks]="true" class="mr-0"
        [previousText]="PAGINATION_PROPERTIES.PREVIOUS_TEXT" [firstText]="PAGINATION_PROPERTIES.FIRST_TEXT"
        [nextText]="PAGINATION_PROPERTIES.NEXT_TEXT" [lastText]="PAGINATION_PROPERTIES.LAST_TEXT" [totalItems]="totalItems"
        [itemsPerPage]="PAGINATION_PROPERTIES.ITEMS_PER_PAGE" [maxSize]="PAGINATION_PROPERTIES.MAX_SIZE" (pageChanged)="pageChangedEvent($event)"
        [rotate]="true">
      </pagination>
    </div>
  </div>
</div>