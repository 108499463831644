<ngx-smart-modal
  #dialog
  [identifier]="MODAL_IDENTIFIERS.DIALOG"
  [dismissable]="false"
  [closable]="false"
  (onOpen)="onOpenModal()"
  customClass="modal-content modal-custom no-padding confirmNewTabData confirm-modal-container">
  <ng-container *ngIf="showModal">
    <div class="confirmationOnNewTab confirm-modal-inner">
      <div class="modal-header">
        <h1 class="header" *ngIf="dialogInput?.data?.header">{{dialogInput?.data?.header|translate}}</h1>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <img class="closeIcon" />
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="dialogInput?.data?.body" class="default content">{{dialogInput?.data?.body|translate}}</p>
        <div *ngIf="dialogInput?.data?.options?.length">
          <div *ngFor="let item of dialogInput.data.options; index as i;" class="radio-field radio-spacing mb-2">
            <input type="radio" name="'radio' + i" id="{{'radio' + i}}" value="{{item.value}}"
              (change)="radioChange($event)">
            <label class="checkbox-text mt-0" for="{{'radio' + i}}">{{item.label}}</label>
          </div>
        </div>
      </div>
      <div class class="modal-footer">
        <button *ngIf="dialogInput?.buttons.cancel" class="bttn bttn-secondary mx-1" (click)="cancel()">{{'buttons.cancel'|translate}}</button>
        <button *ngIf="dialogInput?.buttons.ok" class="bttn bttn-primary mx-1" (click)="ok()">{{'buttons.ok'|translate}}</button>
        <button *ngIf="dialogInput?.buttons?.manual" class="bttn bttn-primary mx-1" (click)="manual()">{{'buttons.manually'|translate}}</button>
        <button *ngIf="dialogInput?.buttons?.auto" class="bttn bttn-primary mx-1" (click)="auto()">{{'buttons.automatically'|translate}}</button>
        <button *ngIf="dialogInput?.buttons.next" class="bttn bttn-primary mx-1" (click)="next()">{{'buttons.next'|translate}}</button>
      </div>
    </div>
  </ng-container>
</ngx-smart-modal>
