import { Injectable } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import { Country } from 'src/app/shared/models/country';
import { Person } from 'src/app/shared/models/d-profilemodel';
import { UnitSystem } from 'src/app/shared/models/uom-models';
import { environment } from 'src/environments/environment';
import { WellHeader } from '../../shared/models/wellHeadermodel';
import { DashboardMenu } from '../models/settingsModel.model';
import { UserModel } from '../models/userModel';
import { ApiService } from './api.service';
import { Job } from 'src/app/shared/models/jobs.model';
import { Config } from './config'
import { UUID } from 'angular2-uuid';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TrajectoryStation } from 'src/app/job/job-shared/trajectory/models/TrajectoryStation';
import { TranslateService } from '@ngx-translate/core';
import { Trajectory } from 'src/app/shared/models/trajectory.model';
import { UomConversionService } from "src/app/core/services/uom-conversion.service";
import { SAVE_DATA } from 'src/app/utils/constants';
import * as LS from 'src/app/utils/localstorage';
import { UnitConversionService } from './unit-conversion.service';
import { ACTION_TYPE, API_RESPONSE, INTERVAL_TIMES, PAGES, TIMEOUT, TOASTER_TYPE } from 'src/app/shared/enums/enums';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ErrorMessageService } from './error-message.service';
import { isNull, isString } from 'src/app/utils/utils';
import { CatalogSyncVersion } from '../models/catalog.model';

@Injectable({
  providedIn: "root",
})

export class UtilitiesService {
  public adasThemeClass: string;
  public pageBlockErrors: boolean;
  public pageHasErrors: boolean;
  public pageCurrent: string;
  public isCloud: boolean;
  public isActiveCloudInitialized = false;
  public activeCloud$ = new BehaviorSubject<boolean>(environment.web.isCloud);
  public isAppInitialized$ = new BehaviorSubject<boolean>(false);
  //public isUserInitialized$ = new BehaviorSubject<boolean>(false);
  public jobAuthenticationInfo: any;

  // user logged in
  public currentUser: UserModel;
  public userModelObj$ = new BehaviorSubject<UserModel>(new UserModel());
  //public userName$ = new Observable<string>();
  public fullName$ = new BehaviorSubject<string>(null);
  public logoInitials$ = new Observable();
  public profileImage$: any;

  // catalogs
  public catJobPurpose: Array<string> = [];
  public catJobStatus: Array<string> = [];
  public catJobCustomer: Array<string> = [];
  public catJobType: Array<string> = [];
  public catGeoCountries: Array<string> = [];
  public catGeoCounties: Array<string> = [];
  public catGeoStates: Array<string> = [];
  public catGeoTimeZones: Array<string> = [];
  public catGeoRegions: Array<string> = [];
  public catGeoRegionsCountries: Array<any> = [];
  public catGeoRegionCountries: Array<string> = [];
  public catWellPurpose: Array<string> = [];
  public catWellOperators: Array<string> = [];
  public catAnalysisType: Array<string> = [];
  public catAnalysisStatus: Array<string> = [];
  public catAnalysisReason: Array<string> = [];
  public catAnalysisCaseReason: Array<string> = [];
  public catBaseFluidType: Array<string> = [];
  public catCalculationBaseFluidType: Array<string> = [];
  public catFluidSystem: Array<string> = [];
  public catBrine: Array<string> = [];
  public catCalculationBrines: Array<string> = [];
  public catPumpPositions: Array<string> = [];
  public catFluidLocations: Array<any> = [];

  //public catPersonnelTypes: any;
  public catContractors: Array<string> = [];
  public catOperators: Array<string> = [];
  public catFluidSets: any;
  public catLithos: any;
  public catLithoTypes: Array<string> = [];
  public catCasings: any;
  public catRisers: any;
  public catTubular: any;
  public currentJob: Job;
  public job: any;
  public well: any;
  public fluidSets: any;
  public diryData = new BehaviorSubject<any>([])
  public recentJobs: string[] = [];

  //public analysisReasons$ = new BehaviorSubject<any>([]);
  public wellObj$ = new BehaviorSubject<WellHeader>(new WellHeader());
  public getQuickLinkRefresh$ = new BehaviorSubject<DashboardMenu>(new DashboardMenu());
  public wellboreDiagramUpdate$ = new BehaviorSubject<boolean>(false);
  public showDashboard$ = new Observable<boolean>();
  public recentJobs$ = new Observable<Array<string>>();
  public tubularStoreproperies: Array<any> = [];
  public countries$ = new BehaviorSubject<any>([]);
  public wellLocations$ = new BehaviorSubject<any>([]);
  public operators$ = new BehaviorSubject<any>([]);
  public wellStatuses$ = new BehaviorSubject<any>([]);
  public jobId$ = new BehaviorSubject<string>('');
  public wellId$ = new BehaviorSubject<string>('');
  public lithoId$ = new BehaviorSubject<string>('');
  public thermalGradientId$ = new BehaviorSubject<string>('');
  public toastr$ = new BehaviorSubject<Object>({});
  public uom$ = new BehaviorSubject<Object>({});
  public refreshUoMData$ = new BehaviorSubject<string>('');
  public trajectoryObj$ = new BehaviorSubject<Trajectory>(new Trajectory());
  public fluidBrines$ = new BehaviorSubject<any>([]);
  public rheologyPredictions$ = new BehaviorSubject<any>([]);
  public baseFluidTypes$ = new BehaviorSubject<any>([]);
  public wellProfileLoader$ = new BehaviorSubject<any>({ wellbore: false, bhaRun: false });

  //public jobId = this.jobId$.asObservable();
  public wellId = this.wellId$.asObservable();
  public lithoId = this.lithoId$.asObservable();
  public thermalGradientId = this.thermalGradientId$.asObservable();

  public columnSetting: any = {};
  public showDashboard: boolean;
  public countries: Country[];
  public jobTypes$;
  public jobPurposes$;
  public riser$ = new Observable<any>();
  public casings$ = new Observable<any>();
  public lithos$ = new Observable<any>();
  public fluidSets$ = new BehaviorSubject<any>({});
  public tubularComponentType = new BehaviorSubject<any>({});
  public tubularComponentTypeDatas = new BehaviorSubject<any[]>([]);

  // observables for catalog values
  public activeJobs$ = new BehaviorSubject<any[]>([]);
  public unitSystems$ = new BehaviorSubject<UnitSystem[]>([]);
  public teamMembers$ = new BehaviorSubject<Person[]>([]);

  // tubular
  public tubularsProperties: Array<any> = [];
  public tubularDyanmicComponents: Array<any> = [];
  public TubularsNozzles: Array<any> = [];
  public tubularGlobalId: string = "";
  public tubularCompoonentId: string = "";
  public TubularcomponentyTypes$ = new Observable<any>();
  public tubularCatalogueDatas: any = [];

  // jobId to duplicate job
  public duplicateJobID: string = "";
  public duplicateJob = { jobId: '', name: '', country: '', state: '', operator: '', location: '' };

  public componentDestroyed$: Subject<boolean> = new Subject();
  public confirmationMessage: string;
  public translations: {};
  public translationsNeededFor: Array<string> =
    [
      'errors.canNotBeEmpty',
      'errors.cannotBeNegative',
      'error.mustBeNumeric',
      'errors.invalidType',
      'errors.SaveFailed',
      'errors.FormErrors',
      'errors.InputErrors',
      'errors.CanPageWithErrorsLeave',
      'errors.analysis.displacementFluidRequired',
      'generic.ConfirmExit',
      'responses.SaveSucceeded'
    ];

  constructor(
    public _auth: MsalService,
    public _api: ApiService,
    public _error: ErrorMessageService,
    public _router: Router,
    public _spinner: NgxSpinnerService,
    public _translate: TranslateService,
    public _uomConversion: UomConversionService,
    public _unitConversion: UnitConversionService,
  ) {
    //this.setOnlineStatus();
    this.showDashboard$ = of(true);
    this._translate.setDefaultLang("en");
    this._translate.use("en");
    this.getTranslationFor(this.translationsNeededFor);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  showSpinner(show: boolean) {
    if (show) {
      let spinner:any = { type: environment.spinner.type, size: environment.spinner.size, color: environment.spinner.color };
      this._spinner.show(undefined, spinner);
    }
    else {
      this._spinner.hide();
    }
  }

  // CanPageWithErrorsLeave interface
  canLeave(): boolean {
    if (this.pageHasErrors) {
      var res = window.confirm(this.getTranslationNow('errors.CanPageWithErrorsLeave'));
      this.pageHasErrors = false;
      return res;
    }
    this.pageHasErrors = false;
    return true;
  };

  setOnlineStatus() {
    // assign API endpoints when local/cloud status changes
    this.activeCloud$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async cloud => {
      console.log('setOnlineStatus: ', Config);
      // clear job with change in data source
      LS.clearJobLocalStorage();
      this.isCloud = cloud;
      LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.online, cloud);
      //this._api.tokenId = tokenId;
      //this._api.accessToken = (tokenId == LS.LOCAL_STORAGE_KEY.Token.Gen) ? await this.getTokenGenerator(false) : "";
      if (this.isActiveCloudInitialized) {
        this._router.navigateByUrl(environment.redirectUri, { skipLocationChange: true }).then(() =>
          this._router.navigate([PAGES.JobsDashboard]));
      }
      this.isActiveCloudInitialized = true;
    });
  }

  public handleAuthError(error: any) {
    console.log('msal:error:', error);
    let message = "";
    LS.clearMsalTokenStorage();
    let page = this._router.routerState.snapshot.url;
    LS.setLocalStorageString(LS.LOCAL_STORAGE_KEY.User.ActivePage, page);
    if (error && isString(error)) message = error;
    if (error && error.errorCode) message = error.errorCode;
    //var online = navigator.onLine;
    if (message.toLowerCase().includes("token") || message.toLowerCase().includes("login")) {
      var status = this._auth.getLoginInProgress;
      this._auth.loginRedirect();
      return;
    }
    this._auth.acquireTokenRedirect({ scopes: environment.consentScopes });
  }

  getTranslationFor(input: string[] | string) {
    this._translate
      .get(input)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        x => {
          this.translations = x;
        });
  }
  getTranslationNow(input: string) {
    var key = input;
    var cached = this.translations[key];
    if (cached) { return cached; }
    var res = this._translate.instant(input);
    if (res) { this.translations[key] = res; }
    return res;
  }
  getTranslationNow2(input: string, parameters: any, useCache = true) {
    var values = Object.values(parameters);
    var key = input + "_" + JSON.stringify(values);
    var cached = this.translations[key];
    if (cached && useCache) { return cached; }
    var res = this._translate.instant(input, parameters);
    if (res) { this.translations[key] = res; }
    return res;
  }

  public getColumn(colSettings: any, columnName: string): FieldLevelUoMSettings {
    return colSettings.find(x => x.colName.toLowerCase() === columnName.toLowerCase());
  }

  public loadTeamMembers() {
    this._api.get<Person[]>(Config.APIUrlCore + environment.personnel.getPersonnelData)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        console.log('loadTeamMembers,get', data);
        this.teamMembers$.next(data);
      });
  }

  // public loadUnitSystems() {
  //   this._api.get<any>(Config.APIUrlCore + environment.unitSystem.getUnitSystems)
  //     .pipe(takeUntil(this.componentDestroyed$))
  //     .subscribe(data => {
  //       console.log('loadUnitSystems.get', data);
  //       this.unitSystems$.next(data);
  //     });
  // }

  public loadActiveJobs() {
    this._api.get<any>(Config.APIUrlCore + environment.jobs.active)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        if (data) {
          console.log('loadActiveJobs.get', data);
          this.activeJobs$.next(data);
        }
      });
  }

  public getFluidSets(jobId_) {
    var url = Config.APIUrlCore + environment.fluids.get;
    return this._api.get<any>(url.replace("{jobId}", jobId_)).subscribe(
      (data) => {
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.FluidSets, data);
      },
      () => {
        let placeholder = {
          "jobId": jobId_,

          "fluidSetId": UUID.UUID(),
          "fluidSetName": "fluidSetName",
          "sAPNumber": "sapNumber",

          "fluidCategory": "fluidCategory",
          "fluidSystem": "fluidSystem",
          "isAerated": false,
          "baseFluidType": "baseFluidType",
          "calculationBaseFluidType": "calculationBaseFluidType",
          "brineType": "brineType",
          "calculationBrineType": "calculationBrineType"

        }

        var url = Config.APIUrlCore + environment.fluids.post;
        this._api.post<any>(url, placeholder).subscribe(() => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.FluidSets, placeholder);
        });
      },
      () => {
      });
  }

  getWellData(wellId) {
    this.getWellbore(wellId);
    this.getBHA_Run(wellId);
    this.getThermalGradients(wellId);
    this.getLithostratigraphy(wellId);
  }

  //-------Data

  public getBHA_Run(wellId) {

    return this._api.get<any>(Config.APIUrlCore + environment.bhaRuns.getByWellId.replace("{wellId}", wellId)).subscribe(() => {
      this.nextWellProfileLoader("bhaRun");
    },
      () => {
        let createBHA_RUN_placeholder = {
          "wellId": wellId,
          "startMd": {
            "value": 0,
            "uom": "ft"
          },
          "startTvd": {
            "value": 0,
            "uom": "ft"
          },
          "endMd": {
            "value": 1,
            "uom": "ft"
          },
          "endTvd": {
            "value": 1,
            "uom": "ft"
          },
          "bitSize": {
            "value": 0,
            "uom": "in"
          },
          "bhaRunWITSML": {
            "bhaRunId": UUID.UUID(),
            "dTimStart": "",
            "dTimStop": "",
            "numStringRun": 1,
            "tubular": {
              "tubularWITSML": {
                "tubularId": UUID.UUID()
              }
            }
          }
        }

        this._api.post<any>(Config.APIUrlCore + environment.bhaRuns.updateBHARun, createBHA_RUN_placeholder).subscribe(() => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.BHA_Run, createBHA_RUN_placeholder);
          this.nextWellProfileLoader("bhaRun");
        });
      },
      () => {
      });
  }
  public getTubulars(tubularId) {

    return this._api.get<any>(Config.APIUrlCore + environment.tubulars.get.replace("{tubularId}", tubularId)).subscribe(() => {     
    },
      () => {
        let createTubular_placeholder = {
          "tubularId": UUID.UUID(),
          "tubularComponentWITSML": {
            "tubularComponentId": UUID.UUID(),
            "description": "5.875 Drill Pipe",
            "id": {
              "value": 0,
              "uom": "in"
            },
            "len": {
              "value": 0,
              "uom": "ft"
            },
            "od": {
              "value": 0,
              "uom": "in"
            },
            "sequence": 0,
            "lenJointAv": {
              "value": 0,
              "uom": "ft"
            },
            "connection": {
              "tubularConnectionId": UUID.UUID(),
              "id": {
                "value": 0,
                "uom": "in"
              },
              "len": {
                "value": 0,
                "uom": "ft"
              },
              "od": {
                "value": 0,
                "uom": "in"
              },
              "sizeThread": {
                "value": 0,
                "uom": "ft"
              }
            },
            "grade": "Other",
            "typeTubularComponent": "drill pipe",
            "wtPerLen": {
              "value": 0,
              "uom": "lb/ft"
            },
            "odFishneck": {
              "value": 0,
              "uom": "in"
            }
          },
          "baraLogixTypeTubularComponent": {
            "baraLogix": "Drill Pipe",
            "wITSML": "drill pipe"
          }
        }

        this._api.post<any>(Config.APIUrlCore + environment.tubulars.post, createTubular_placeholder).subscribe(() => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Tubulars, createTubular_placeholder);

        });
      },
      () => {
      });
  }
  public getWellbore(wellId) {

    return this._api.get<any>(Config.APIUrlCore + environment.wellbores.getWellboresByWellId.replace("{wellId}", wellId)).subscribe((data) => {
      console.log('getWellbore.get', data);
      this.nextWellProfileLoader("wellbore");
    },
      (err) => {
        const wellboreId = UUID.UUID();
        let createWellbore_placeholder = {
          "wellboreTitle": "Original",
          "wellboreId": wellboreId,
          "parentWellboreTitle": "",
          "typeWellbore": "Initial",
          "dTimKickoff": "2020-08-22T05:00:00Z",
          "dTimTD": "2020-09-22T15:00:00Z",
          "md": {
            "value": 0,
            "uom": "ft",
          },
          "mdKickoff": {
            "value": 0,
            "uom": "ft",
          },
          "tvd": {
            "value": 0,
            "uom": "ft",
          },
          "tvdKickoff": {
            "value": 0,
            "uom": "ft",
          },
          "wellboreGeometry": {
            "wellboreGeometryWITSML": {
              "wellboreGeometryId": UUID.UUID(),
            },
          },
          "wellId": wellId,
          "trajectory": {
            "wellId": wellId,
            "wellboreId": wellboreId,
            "calculateTvdFromInclination": true,
            "trajectoryWITSML": {
              "trajectoryId": UUID.UUID()
            }
          }
        }

        this._api.post<any>(Config.APIUrlCore + environment.wellbores.createOrUpdateWellboreMode.replace("{mode}", "Normally"), createWellbore_placeholder).subscribe(response => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.WELLBORES, createWellbore_placeholder);
          this.nextWellProfileLoader("wellbore");
        });
      },
      () => {
      });
  }

  private nextWellProfileLoader(type) {
    let loader = this.wellProfileLoader$.value;
    if(type === "wellbore") {
      loader.wellbore = true;
    }

    if(type === "bhaRun") {
      loader.bhaRun = true;
    }

    this.wellProfileLoader$.next(loader);
  }
  // public getCasingLiners(wellboreId) {
  //   return this._api.get<any>(Config.APIUrlCore + environment.wellboreGeometry.casings.getByWellboreId.replace("{wellboreId}", wellboreId)).subscribe(() => {
  //   },
  //     () => {
  //     },
  //     () => {
  //     });
  // }
  // public getRisers(wellboreId) {
  //   return this._api.get<any>(Config.APIUrlCore + environment.wellboreGeometry.risers.getByWellboreId.replace("{wellboreId}", wellboreId)).subscribe(() => {
  //   },
  //     () => {
  //     },
  //     () => {
  //     });
  // }
  // public getOpenholes(wellboreId) {
  //   return this._api.get<any>(Config.APIUrlCore + environment.wellboreGeometry.openholes.getByWellboreId.replace("{wellboreId}", wellboreId)).subscribe(() => {
  //   },
  //     () => {
  //     },
  //     () => {
  //     });
  // }

  public getThermalGradients(wellId) {
    return this._api.get<any>(Config.APIUrlCore + environment.temperatureProfiles.get.replace("{wellId}", wellId)).subscribe((data) => {
      console.log('getThermalGradients.get', data);
      if (data) {
        this.wellId$.next(data.wellId);
      }

    },
      () => {
        let createthermalGradients_placeholder = {
          "wellId": wellId,
          "thermalGradientId": UUID.UUID(),
          "variableList": [
            "TVD",
            "Value"
          ],
          "unitList": [
            "ft",
            "°F"
          ],
          "unitTypeList": [
            "Depth",
            "Temperature"
          ],
          "dataTypeList": [
            "double",
            "double"
          ],
          "data": [
            [0, 50]
            // [
            //   "0.0",
            //   "68.0"
            // ],
            // [
            //   "10.0",
            //   "20.0"
            // ]
          ]
        }

        this._api.post<any>(Config.APIUrlCore + environment.temperatureProfiles.post, createthermalGradients_placeholder).subscribe(() => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.GeoThermal, createthermalGradients_placeholder);
          console.log('getThermalGradients.post', createthermalGradients_placeholder);
          this.wellId$.next(createthermalGradients_placeholder.wellId);
        });
      },
      () => {
      });
  }

  public getLithostratigraphy(wellId) {

    return this._api.get<any>(Config.APIUrlCore + environment.lithostratigraphies.get.replace("{wellId}", wellId)).subscribe((data) => {
      console.log('getLithostratigraphy.get', data);
      this.lithoId$.next(data.lithostratigraphyId);
    },
      () => {
        let createLithostratigraphy_placeholder = {
          "wellId": wellId,
          "lithostratigraphyId": UUID.UUID()
        }

        this._api.post<any>(Config.APIUrlCore + environment.lithostratigraphies.post, createLithostratigraphy_placeholder).subscribe(() => {

          this.lithoId$.next(createLithostratigraphy_placeholder.lithostratigraphyId);

          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Lithostratigraphy, createLithostratigraphy_placeholder);

        });
      },
      () => {
      });
  }

  public getLithostratigraphyStations(lithostratigraphyId) {

    return this._api.get<any>(Config.APIUrlCore + environment.lithostratigraphies.lithostratigraphiesStations.get.replace("{lithostratigraphyId}", lithostratigraphyId)).subscribe(() => {
    },
      () => {
        let createLithostratigraphyStations_placeholder = {
          "lithostratigraphyId": lithostratigraphyId,
          "lithostratigraphyStationId": UUID.UUID(),
          "description": "14M Sand",
          "group": "Cotton Valley",
          "period": "Jurassic Lower",
          "lithologyType": "Sandstone",
          "country": "United States of America",
          "topTvd": {
            "value": 0,
            "uom": "ft"
          },
          "endTvd": {
            "value": 0,
            "uom": "ft"
          }
        }

        this._api.post<any>(Config.APIUrlCore + environment.lithostratigraphies.lithostratigraphiesStations.post, createLithostratigraphyStations_placeholder).subscribe(() => {
          LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Lithostratigraphy_Stations, createLithostratigraphyStations_placeholder);

        });
      },
      () => {
      });
  }


  // Catalogs

  public storeCatalogs(key: string, data: any): boolean {
    var val = JSON.stringify(data);
    if (val != "[]") {
      LS.setLocalStorage(key, val);
      return true;
    }
    else {
      return false;
    }
  }

  public async loadCatalogJobType(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.Type;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catJobType = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.jobTypes;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catJobType = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogJobPurpose(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.Purpose;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catJobPurpose = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.jobPurpose;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catJobPurpose = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogJobStatus(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.Status;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catJobStatus = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.jobStatus;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catJobStatus = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogJobCustomerType(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.CustomerType;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catJobCustomer = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.jobCustomer;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catJobCustomer = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogContractors(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.Contractors;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catContractors = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.contractors;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          data = data.filter((n, i) => data.indexOf(n) === i);
          this.storeCatalogs(key, data);
          this.catContractors = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }
  public async loadCatalogOperators(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Job.Operators;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catOperators = data;
      return data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.operators;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          data = data.filter((n, i) => data.indexOf(n) === i);
          this.storeCatalogs(key, data);
          this.catOperators = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
  }

  public async loadCatalogWellPurpose(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Purposes;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catWellPurpose = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.wellPurpose;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catWellPurpose = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogWellOperators(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Operators;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catWellOperators = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.operators;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catWellOperators = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogFluidSets(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.FluidSet;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catFluidSets = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.fluidSets;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catFluidSets = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadJobCatalogs(forceLoad: boolean) {
    forceLoad = this.checkCatalogExpiration(forceLoad);
    var promises = [];
    (!forceLoad && this.catJobType && (this.catJobType.length > 0)) || promises.push(this.loadCatalogJobType(false));
    (!forceLoad && this.catJobCustomer && (this.catJobCustomer.length > 0)) || promises.push(this.loadCatalogJobCustomerType(forceLoad));
    (!forceLoad && this.catJobPurpose && (this.catJobPurpose.length > 0)) || promises.push(this.loadCatalogJobPurpose(forceLoad));
    (!forceLoad && this.catJobStatus && (this.catJobStatus.length > 0)) || promises.push(this.loadCatalogJobStatus(forceLoad));
    (!forceLoad && this.catContractors && (this.catContractors.length > 0)) || promises.push(this.loadCatalogContractors(forceLoad));
    //(!forceLoad && this.catOperators && (this.catOperators.length > 0)) || promises.push(this.loadCatalogOperators(forceLoad));

    return Promise.all(promises)
      .then(resp => {
        console.log("Job catalogs loaded");
        let errs = this.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(err => {
        console.log("Job catalogs error");
        return null;
      });
  }


  //analysis catalog
  public async loadAnalysisType(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Analysis.Type;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catAnalysisType = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.analysis.type;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catAnalysisType = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadAnalysisStatus(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Analysis.Status;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catAnalysisStatus = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.analysis.status;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catAnalysisStatus = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadAnalysisReason(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Analysis.Reason;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catAnalysisReason = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.analysis.reason;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catAnalysisReason = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  //analysis case catalog
  public async loadAnalysisCaseReason(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.AnalysisCase.Reason;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catAnalysisCaseReason = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.analysisCase.reason;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catAnalysisCaseReason = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadBaseFluidTypes(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.BaseFluidTypes
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catBaseFluidType = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.baseFluidTypes;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catBaseFluidType = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCalculationBaseFluidTypes(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.CalculationBaseFluidTypes;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catCalculationBaseFluidType = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.calculationBaseFluidTypes
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catCalculationBaseFluidType = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadFluidSystems(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.FluidSystems
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catFluidSystem = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.fluidSystems
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catFluidSystem = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadBrines(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.Brines
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catBrine = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.brines;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catBrine = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadCalculationBrines(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.CalculationBrines;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catCalculationBrines = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.calculationBrines
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catCalculationBrines = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadPumpPositions(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pump_Positions;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catPumpPositions = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.pumpPositions;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catPumpPositions = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadFluidLocations(forceLoad: boolean){
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.FluidLocations;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catFluidLocations = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.fluidCatalogs.fluidLocations;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catFluidLocations = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadAnalysisCatalogs(forceLoad: boolean) {
    forceLoad = this.checkCatalogExpiration(forceLoad);
    var promises = [];
    (!forceLoad && this.catAnalysisType && (this.catAnalysisType.length > 0)) || promises.push(this.loadAnalysisType(false));
    (!forceLoad && this.catAnalysisStatus && (this.catAnalysisStatus.length > 0)) || promises.push(this.loadAnalysisStatus(false));
    (!forceLoad && this.catAnalysisReason && (this.catAnalysisReason.length > 0)) || promises.push(this.loadAnalysisReason(false));
    (!forceLoad && this.catAnalysisCaseReason && (this.catAnalysisCaseReason.length > 0)) || promises.push(this.loadAnalysisCaseReason(false));
    (!forceLoad && this.catBaseFluidType && (this.catBaseFluidType.length > 0)) || promises.push(this.loadBaseFluidTypes(false));
    (!forceLoad && this.catFluidSystem && (this.catFluidSystem.length > 0)) || promises.push(this.loadFluidSystems(false));
    (!forceLoad && this.catBrine && (this.catBrine.length > 0)) || promises.push(this.loadBrines(false));
    (!forceLoad && this.catPumpPositions && (this.catPumpPositions.length > 0)) || promises.push(this.loadPumpPositions(false));
    (!forceLoad && this.catFluidLocations && (this.catFluidLocations.length > 0)) || promises.push(this.loadFluidLocations(false));
    
    return Promise.all(promises)
      .then(resp => {
        let errs = this.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(err => {
        return null;
      });
  }

  public async loadFluidPropertiesCatalogs(forceLoad: boolean) {
    forceLoad = this.checkCatalogExpiration(forceLoad);
    var promises = [];
    (!forceLoad && this.catFluidSystem && (this.catFluidSystem.length > 0)) || promises.push(this.loadFluidSystems(false));
    (!forceLoad && this.catCalculationBrines && (this.catCalculationBrines.length > 0)) || promises.push(this.loadCalculationBrines(false));
    (!forceLoad && this.catCalculationBaseFluidType && (this.catCalculationBaseFluidType.length > 0)) || promises.push(this.loadCalculationBaseFluidTypes(false));
    
    return Promise.all(promises)
      .then(resp => {
        let errs = this.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(err => {
        return null;
      });
  }

  // checks cache for catalog expiration date, forces reload if missing or expired
  public checkCatalogExpiration(forceLoad: boolean): boolean {
    if (!forceLoad) {
      var key = LS.LOCAL_STORAGE_KEY.Catalogs.Expire;
      const expiry = LS.getLocalStorage(key);
      if (expiry < 0) return false;
      const now = new Date();
      forceLoad = (!expiry || now.getTime() > expiry);
      if (forceLoad) {
        LS.clearCatalogLocalStorage();
        // set expiration
        var interval = INTERVAL_TIMES.CatalogExpire;
        var expireDate = interval < 0 ? interval : now.getTime() + interval;
        LS.setLocalStorage(key, expireDate);
      }
    }
    return forceLoad;
  }

  //delete this function when api is ready
  public getTheNotificationBar(): Observable<Object> {
    return this.toastr$.asObservable();
  }
  public showNotificationBar(val: Object) {
    this.toastr$.next(val);
  }
  public showDashbaord(val: boolean) {
    this.showDashboard$ = of(val);
  }
  public setUserModelObj(val: UserModel) {
    this.currentUser = val; // required for app.component.ts
    this.userModelObj$.next(val);
    //this.userName$ = of(val.fullName);
    this.logoInitials$ = of(this.logoInitials(val.fullName));
    if (val.recentJobs) {
      this.recentJobs$ = of(val.recentJobs);
    }
  }
  // purpose?
  public setQuickLinkRefresh(val: DashboardMenu) {
    return this.getQuickLinkRefresh$.next(val);
  }
  public getUoMRefreshData(): Observable<string> {
    return this.refreshUoMData$.asObservable();
  }
  public setUoMRefreshData(val: string) {
    this.refreshUoMData$.next(val);
  }
  public getUOM(): Observable<Object> {
    return this.uom$.asObservable();
  }
  public showUOMOverlay(event, jobId, columnSetting, componentId, sampleValue, catalogue?) {
    let uomParams = {
      event: event,
      jobId: jobId,
      columnSetting: columnSetting,
      componentId: componentId,
      sampleValue: sampleValue != null ? sampleValue : 0,
      catalogue: catalogue
    }
    this.uom$.next(uomParams);
  }

  public clearToastr() {
    let notification = new Object();
    notification = {
      type: "",
      message: ""
    };
    this.showNotificationBar(notification);
  }

  public showToastr(type, message) {
    let notification = new Object();
    notification = {
      type: type,
      message: message
    };
    this.showNotificationBar(notification);
  }

  public showToastrWithTimer(type, message, timeSpan: number) {
    let notification = new Object();
    notification = {
      type: type,
      message: message,
      timeSpan: timeSpan
    };
    this.showNotificationBar(notification);
  }

  // logic for when to show validation errors
  showValidation(group: any, controlName: string) {
    var ctl = controlName ? group.get(controlName) : group;
    // if (controlName === null) {
    //   let a = 1;
    // }
    // else {
    //   let a = 2;
    // }
    // if (ctl.touched) {
    //   let a = 1;
    // }
    // if (!ctl.pristine) {
    //   let a = 1;
    // }
    var show = ctl ? !ctl.valid && (ctl.touched || !ctl.pristine) : false;
    return show;
  }

  showValidationCustom(group: any, controlName: string, isSubmitted: boolean) {
    var ctl = controlName ? group.get(controlName) : group;
    var show = ctl ? !ctl.valid && isSubmitted : false;
    return show;
  }

  public checkPromiseAll(values: Array<any>, messages: Array<any>, name: string): boolean {
    // The promise must return Success else will be seen as an error.
    let err = values.find(x => x && x != API_RESPONSE.SUCCESS);
    let message = "";
    messages || (messages = []);
    if (err) {
      message = this.getTranslationNow('toastr.SaveFailed');
      for (let i = 0; i < values.length; i++) {
        let res = values[i];
        let msg = messages[i];
        if (res instanceof HttpErrorResponse) {
          message = message + '<br>';
          if (msg) message = message + msg + ". ";
          message = message + (<HttpErrorResponse>res).error.Message;
          (<HttpErrorResponse>res).error.ValidationErrors.forEach(err => {
            message = message + ". <br>(" + err + ")";
          });
          //message = message + ')';
        }
      }
      this.showToastrWithTimer(TOASTER_TYPE.ERROR, message, SAVE_DATA.Toastr_Fail);
      return false;
    }
    else {
      message = this.getTranslationNow('toastr.SaveSucceeded');
      // for (let i = 0; i < values.length; i++) {
      //   let res = values[i];
      //   let msg = messages[i];
      //   if (msg) message = message + '<br>(' + msg + ")";
      // }
      this.showToastrWithTimer(TOASTER_TYPE.SUCCESS, message, SAVE_DATA.Toastr_Success);
      return true;
    }
  }

  public promiseAll(promises: Array<Promise<any>>, messages: Array<string>, actionType?: ACTION_TYPE): Promise<boolean> {

    return Promise.all(promises).then(values => {
      let err = values.find(x => x && x != API_RESPONSE.SUCCESS);
      let msg = "";
      messages = promises.length > 1 ? messages : [];
      messages || (messages = []);
      if (err) {
        for (let i = 0; i < values.length; i++) {
          let res = values[i];
          let message = messages[i];
          if (res instanceof HttpErrorResponse) {
            if(i !== values.length -1) msg = msg + '<br>';
            if (message) msg = msg + message + ". ";
            if (res.error.ValidationErrors) {
              for (let msgError of res.error.ValidationErrors) {
                msg += msgError + "<br>";
              }
            } else {
              msg = msg + res.error;
            }
            
          }
        }
        this.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        return false;
      }
      else {
        var item = this.getTranslationNow('item.record');
        switch (actionType) {
          case ACTION_TYPE.CREATE:
            msg = this.getTranslationNow2('toastr.itemcreated', { item: item });
            //msg = "New record is created successfully.";
            break;
          case ACTION_TYPE.UPDATE:
            msg = this.getTranslationNow2('toastr.itemupdated', { item: item });
            //msg = "Update record successfully.";
            break;
          case ACTION_TYPE.DELETE:
            msg = this.getTranslationNow2('toastr.itemdeleted', { item: item });
            //msg = "Record is deleted successfully.";
            break;
          default:
            msg = this.getTranslationNow('toastr.SaveSucceeded');
            break;
        }
        this.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
        return true;
      }
    }).catch(error => {
      var msg = this.getTranslationNow('toastr.SaveFailed');
      this.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
      return false;
    });
  }

  public async updateCatalogsLocal() {
    let url = Config.APIUrlCatalogs + environment.catalogs.syncToLocal;
    return await this._api.get(url).toPromise()
    .then(res => {
      return API_RESPONSE.SUCCESS;
    })
    .catch(err => {
      return err;
    });
  }

  public checkCatalogVersion(): Observable<CatalogSyncVersion>{
    let url = Config.APIUrlCatalogs + environment.catalogs.checkForUpdates;
    return new Observable<CatalogSyncVersion>((obj)=>{
      this._api.get(url).timeout(TIMEOUT.MEDIUM).subscribe(
        (res:CatalogSyncVersion)=>{
        obj.next(new CatalogSyncVersion(res))
        obj.complete()
      },
      (err)=>{
        obj.next(new CatalogSyncVersion(null))
        obj.complete()
      })
    })
  }

  public checkPromiseErrors(values: Array<any>): Number {
    let errorCount = 0;
    for (let i = 0; i < values.length; i++) {
      let res = values[i];
      if (res instanceof HttpErrorResponse) {
        errorCount = errorCount + 1;
      }
    }
    return errorCount;
  }

  public getErrorMessage(httpError: any) {
    let errorMessage = "";
    if (httpError && httpError.error) {

      //handle for status 500
      if (httpError.status === 500) {
        if (typeof (httpError.error) == "string") {
          return httpError.error;
        }

        for (let message of httpError.error) {
          errorMessage += message + "<br>";
        }
        return errorMessage;
      }

      //handle for status 400
      if (httpError.status === 400) {
        if (httpError.error.ValidationErrors) {
          for (let message of httpError.error.ValidationErrors) {
            errorMessage += message + "<br>";
          }
        }
        return errorMessage;
      }

      if (httpError.status === 404) {
        return httpError.error;
      }

      return httpError.message;

    }
    return errorMessage;
  }
  //---
  setWellObj(well) {
    this.wellObj$.next(well)
  }

  setAllId(jobId) {
    this.jobId$.next(jobId)
    //this.getRigId(jobId);
    this.getWellId(jobId);
    //this.getFluidSetId(jobId);
    console.log("setAllId", jobId);
  }

  // public getRigId(jobId_) {
  //   var url = Config.APIUrlCore + environment.rigs.get.replace("{jobId}", jobId_);
  //   return this.api.get<Rig>(url).subscribe(
  //     (data) => {
  //       // spread returned data over new instance to ensure all properties
  //       var def = new Rig();
  //       data = { ...def, ...data };
  //       this.rig = data;
  //       this.rigId$.next(data.rigWITSML.rigId);
  //       this.rigIdString = data.rigWITSML.rigId;
  //       console.log(data);
  //     },
  //     (err) => {
  //       // if api call for existing rig fails, create placeholder to create new rig
  //       this.rig = new Rig();
  //       this.rig.jobId = jobId_;

  //       var url = Config.APIUrlCore + environment.rigs.post;
  //       this.api.post<Rig>(url, this.rig).subscribe(response => {
  //         this.getRigId(jobId_);
  //       });
  //     },
  //     () => {
  //     }
  //   );
  // }

  public getFluidSetId(jobId_) {
    return this._api.get<any>(Config.APIUrlCore + environment.fluids.get.replace('{jobId}', jobId_)).subscribe((data) => {
      console.log("getFluidSetId.get", data);
    },
      () => {

        let createFluidSet_placeholder = {
          "fluidSetId": UUID.UUID(),
          "fluidSetName": "Manufacturer 1",
          "sAPNumber": "MudPump Model 1",
          "fluidCategory": "fluidCategory",
          "fluidSystem": "fluidSystem",
          "isAerated": false,
          "baseFluidType": "baseFluidType",
          "calculationBaseFluidType": "calculationBaseFluidType",
          "brineType": "brineType",
          "calculationBrineType": "calculationBrineType",
          "jobId": jobId_
        }

        //this.rigId$.next(createRig_placeholder.rigWITSML.rigId)
        this.fluidSets$.next(createFluidSet_placeholder.fluidSetId)
        this.fluidSets = createFluidSet_placeholder

        this._api.post<any>(Config.APIUrlCore + environment.fluids.post, createFluidSet_placeholder).subscribe(() => {
          this.getFluidSetId(jobId_)
        });


      },
      () => {
      }

    );
  }

  //getwellid observable
  public getWellId(jobId_) {

    return this._api.get<any>(Config.APIUrlCore + environment.wells.getByJobId.replace('{jobId}', jobId_)).subscribe((data) => {
      console.log("getWellId.get", data);
      this.wellId$.next(data.wellWITSML.wellId);
      this.getWellData(data.wellWITSML.wellId);
    },
      () => {

        let createWell_placeholder = {
          "jobId": jobId_,
          "wellType": "On-Shore",
          "isTightHole": false,
          "isCriticalFirstWell": false,
          wellWITSML: {
            wellId: UUID.UUID(),
            "nameLegal": "Well Name",
            "numAPI": "000000000000",
            "numPCN": "",
            "statusWell": "Inactive",
            "purposesWell": ["Test"],
            "latitude": 0,
            "longitude": 0,
            "dTimSpud": new Date().toISOString(),
            "country": "United States of America",
            "state": "Texas",
            "county": "",
            "basin": "",
            "field": "",
            "block": "",
            "district": "",
            "region": "",
            "operator": "1988 INDEXGEO-JV",
            "timezone": "GMT-06:00 Central Time",
            "waterDepth": {
              "value": 0,
              "uom": "ft"
            }
          }
        }

        this.well = createWell_placeholder
        this._api.post<any>(Config.APIUrlCore + environment.wells.post, createWell_placeholder).subscribe(response => {
          console.log("getWellId.post", response);
          this.wellId$.next(createWell_placeholder.wellWITSML.wellId)
          this.getWellData(createWell_placeholder.wellWITSML.wellId);
        });
      },
      () => {
      }

    );
  }

  public getTrajectoryStations = (trajectoryid: string): Observable<Array<TrajectoryStation>> =>
    this._api.get(Config.APIUrlCore + environment.trajectories.trajectoryStation.get.replace("{trajectoryId}", trajectoryid));

  public async loadCatalogCasings(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Casings;
    if (!forceLoad) { var data = LS.getLocalStorageString(key); }
    if (data) {
      this.catCasings = JSON.parse(data);
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.tubularCatalogs.casingsLiners;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catCasings = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogRisers(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Risers;
    if (!forceLoad) { var data = LS.getLocalStorageString(key); }
    if (data) {
      this.catRisers = JSON.parse(data);
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.tubularCatalogs.risers;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catRisers = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogTubularComponentTypes(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Tubular.ComponentTypes;
    if (!forceLoad) { var data = LS.getLocalStorageString(key); }
    if (data) {
      //debugger;
      this.catTubular = data
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.tubularCatalogs.components;
      return await this._api.get<any>(url).toPromise().then((data) => {
        this.storeCatalogs(key, data);
        this.catTubular = data
        return data;
      })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogLithoTypes(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.LithoTypes;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catLithoTypes = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.lithologyType;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catLithoTypes = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogLithos(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Lithos
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catLithos = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.lithos;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catLithos = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  public async loadCatalogCountries(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.Countries;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catGeoCountries = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.countries;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catGeoCountries = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
    return data;
  }

  // catalog of countries within specified region
  public async loadCatalogRegionCountries(regionName: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.RegionsCountries + "-" + regionName;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catGeoRegionCountries = data;
    }
    else {
      //var url = Config.APIUrlMock + environment.catalogs.wellCatalogs.regioncountries;
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.regioncountries;
      url = url.replace("{regionName}", regionName);
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catGeoRegionCountries = data;
          return data;
        })
        .catch(err => {
          this.catGeoRegionCountries = [];
          return err;
        });
    }
    return data;
  }

  // catalog of all regions, maybe not needed
  public async loadCatalogRegions(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.Regions;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catGeoRegions = data;
    }
    else {
      //var url = Config.APIUrlMock + environment.catalogs.wellCatalogs.regions;
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.regions;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catGeoRegions = data;
          return data;
        })
        .catch(err => {
          this.catGeoRegions = [];
          return err;
        });
    }
    return data;
  }

  // catalog of all regions with countries in each region
  public async loadCatalogRegionsCountries(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.RegionsCountries;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catGeoRegionsCountries = data;
      this.catGeoRegions = this.catGeoRegionsCountries.map(x => x.region);
    }
    else {
      //var url = Config.APIUrlMock + environment.catalogs.wellCatalogs.regionscountries;
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.regionscountries;
      return await this._api.get<any[]>(url).toPromise()
        .then(data => {
          this.storeCatalogs(key, data);
          this.catGeoRegionsCountries = data;
          this.catGeoRegionCountries = [];
          this.catGeoRegions = data.map(x => x.region);
          this.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Geo.Regions, this.catGeoRegions);
          data.forEach(x => {
            let regionName = x.region;
            let regionData = x.countries;
            this.storeCatalogs(key + "-" + regionName, regionData);
          });
          return data;
        })
        .catch(err => {
          this.catGeoRegionsCountries = [];
          this.catGeoRegions = [];
          return err;
        });
    }
    return data;
  }


  //----
  // to delete
  public jobObj$ = new BehaviorSubject<any>({});
  setJobObj(job) {
    this.jobObj$.next(job);
  }
  //
  updateWellboreDiagram(val: boolean) {
    this.wellboreDiagramUpdate$.next(val);
  }
  sortAlertsinJob(job: any) {
    if (job.alerts !== null && job.alerts.length > 0) {
      for (let alerts of job.alerts) {
        alerts.statusCode = alerts.status === "red" ? 1 : (alerts.status === "yellow" ? 2 : 3);
      }

      job.alerts.sort((x, y) => {
        if (x.statusCode > y.statusCode) {
          return 1;
        }
        if (x.statusCode === y.statusCode) {
          return 0;
        }
        if (x.statusCode < y.statusCode) {
          return -1;
        }
      });

      job.colorCode = job.alerts[0].status === "red" ? "card-error" : (job.alerts[0].status === "yellow" ? "card-info" : "card-success");
    }
  }
  buildChartSeries(o, yValue, usedFields, independentVar, options) {
    let seriesArray = [];
    for (let i = 0; i < o.fields.length; i++) {
      // Skip fields that shouldnt be graphed
      if (!usedFields.includes(o.fields[i])) { continue; }

      let tempObj = {
        type: 'line',
        name: o.names !== undefined ? o.names[i] : o.fields[i],
        symbol: 'none',
        encode: {
          y: independentVar === 'y' ? o.fields[yValue] : o.fields[i],
          x: independentVar === 'y' ? o.fields[i] : o.fields[yValue],
        },
        label: {
          position: 'right',
          show: false,
        }
      };

      if ((o.colors !== undefined) && (o.colors[i] !== undefined)) {
        tempObj["itemStyle"] = { color: o.colors[i] };
      }
      // Create Object for echarts
      seriesArray.push(this.mergeDeep(tempObj, options));
    }
    return seriesArray;
  }
  extend(obj, src) {
    for (var key in src) {
      if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
  }
  buildMultiAxisChartSeries(o, yValue, usedFields, independentVar, options) {
    // let bigArray = [];
    let seriesArray = [];

    // Loop though arrays in userd Fields - Thats how you choose how may axies you graph.
    for (let i = 0; i < usedFields.length; i++) {
      // Loop though the elements each array to build seires array.
      for (let e = 1; e < o.fields.length; e++) {
        // Skip fields that shouldnt be graphed
        if (!usedFields[i].includes(o.fields[e])) { continue; }

        const lineType = i === 0 ? 'solid' : 'dashed';

        let tempObj = {
          name: o.fields[e],
          data: o.data,
          dimensions: o.fields,
          encode: {
            y: independentVar === 'y' ? o.fields[yValue] : o.fields[e],
            x: independentVar === 'y' ? o.fields[e] : o.fields[yValue],
          },
          type: 'line',
          lineStyle: {
            type: lineType
          },
          symbol: 'none',
          tooltip: {}
        };

        if (independentVar === 'y') { tempObj['xAxisIndex'] = i }
        if (independentVar === 'x') { tempObj['yAxisIndex'] = i }

        const mergedObj = this.mergeDeep(tempObj, options);

        // Create Object for echarts
        seriesArray.push(mergedObj);
      }
    }

    return seriesArray
  }
  buildTableDataRows(tableData) {
    let tableObj = {};

    tableObj['dataRows'] = tableData.dataRows.map(element => {
      let temp = {};
      element.map((item, i) => { return temp[tableData.fields[i]] = item; })
      return temp
    });

    tableObj['columnSettings'] = tableData.columnSettings;

    tableObj['headers'] = tableData.fields.map(item => {
      let colSet = tableData.columnSettings.find(colSet => { return colSet.colName == item });
      // if (colSet === undefined || colSet.currentUnit == 'Unknown' || colSet.currentUnit == 'dimensionless' || colSet.currentUnit == '') {
      if (colSet === undefined || colSet.currentUnit == 'Unknown' || colSet.currentUnit == 'dimensionless' || colSet.currentUnit == '') {
        return { title: item, uom: false, key: item };
      } else {
        return { title: item, uom: true, key: item };
      }
    });

    return tableObj;
  }
  public logoInitials(value: string) {
    if (value) {
      let arr = value.split(" ");
      if (arr && arr.length > 1) {
        return arr[0].charAt(0) + "" + arr[1].charAt(0);
      } else if (arr.length == 1) {
        return arr[0].charAt(0) + arr[1].charAt(0);
      } else if (arr.length == 1) {
        return arr[0].charAt(0);
      }
      else { return ''; }
    }
  }
  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
  mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  // convert nested object to formgroup with nested formgroups, formcontrol only for primitive property
  mapToAbstractControl(data: any): AbstractControl {
    try {
      var isobject = this.isPlainObject(data);
      if (isobject) {
        const formGroupDescription = {};
        Object.keys(data).forEach(key => {
          formGroupDescription[key] = this.mapToAbstractControl(data[key]);
        });
        return new FormGroup(formGroupDescription);
      } else if (Array.isArray(data)) {
        const formArrayDescription = [];
        data.forEach(elem => {
          formArrayDescription.push(this.mapToAbstractControl(elem));
        });
        return new FormArray(formArrayDescription);
      } else {
        return new FormControl(data);
      }
    } catch (error) {
      return null;
    }
  }
  isPlainObject(obj) {
    if (obj === null) return false;
    const prototype = Object.getPrototypeOf(obj);
    var typ = (typeof obj);
    return typ == 'object' || prototype === null || prototype.constructor === Object;
  }
  isPrimitive(x) {
    return x === null || (typeof x !== "function" && typeof x !== "object")
  }

  /*
 * Flatten Object @gdibble: Inspired by https://gist.github.com/penguinboy/762197
 *   input:  { 'a':{ 'b':{ 'b2':2 }, 'c':{ 'c2':2, 'c3':3 } } }
 *   output: { 'a.b.b2':2, 'a.c.c2':2, 'a.c.c3':3 }
 */
  flattenObject(obj, prefix = '', res = {}) {
    Object.entries(obj).reduce((r, [key, val]) => {
      const k = `${prefix}${key}`
      if (typeof val === 'object' && val != null) {
        this.flattenObject(val, `${k}__`, r)
      } else {
        res[k] = val
      }
      return r
    }, res)
    return res;
  }

  // format user input template fields after setting values
  formatInput(input: AbstractControl, fields: Array<string>, colSettings: Array<any>, options: { FixedDecimals, ConvertValues, ClearZeroes }) {
    fields.forEach((key) => {
      var col = colSettings.find(x => x.colName == key);
      if (col) {
        var val = this.getControlValue(input, key);
        if (val != null) {
          //var value = this._uomConversion.calculateConvertedValueDisplay(col, val);
          //this.setControlValue(input, key, value);
        }
      }
    });
  }

  formatInput_Decimals(input: AbstractControl, fields: Array<string>, colSettings: Array<any>) {
    fields.forEach((key) => {
      var col = colSettings.find(x => x.colName == key);
      if (col) {
        var val = this.getControlValue(input, key);
        if (val != null) {
          var value = this._uomConversion.calculateConvertedValueDisplay(col, val);
          this.setControlValue(input, key, value);
        }
      }
    });
  }

  formatInput_ZeroAsNull(input: AbstractControl, fields: Array<string>) {
    fields.forEach((key) => {
      var val = this.getControlValue(input, key);
      if (val == 0) {
        this.setControlValue(input, key, '');
      }
    });
  }

  // set readonly fields in an object as empty
  formatObject_DeleteReadOnly(obj: any, fields: Array<string>): any {
    fields.forEach((key) => {
      let item = obj[key];
      let typ = typeof obj[key];
      if (item && typ === "object") {
        if ("value" in item) {
          obj[key].value = 0
        }
      }
      else if (typ === "number" || item === null) {
        obj[key] = 0;
      }
      else if (typ === "string") {
        obj[key] = "";
      }
    });
    return obj;
  }

  isUomValue(obj: any): boolean {
    return (obj && (typeof obj === "object") && (obj.value || obj.value === 0) && obj.uom) ? true : false;
  }

  getControl(input: AbstractControl, field: string) {
    return input.get(field);
  }
  getControlValue(input: AbstractControl, field: string) {
    var ctl = this.getControl(input, field);
    return (ctl != null) ? ctl.value : null;
  }
  setControlValue(input: AbstractControl, field: string, value: string) {
    var ctl = this.getControl(input, field);
    return (ctl != null) ? ctl.setValue(value) : null;
  }

  // convert from API value to unit system value and return as number, no precision rounding
  convertValue(value: any, column: any): number {
    return value ? Number(this._unitConversion.convertValue(column, value)) : value;
  }

  // revert to API value from unit system value and return as number
  revertValue(value: any, column: any): number {
    if (this.convertQualify(value, column)) {
      var revertcolumn = Object.assign({}, column);
      //var revertcolumn = JSON.parse(JSON.stringify(column));
      revertcolumn.currentUnit = column.unit;
      revertcolumn.unit = column.currentUnit;
      let convertedValue = this._unitConversion.convertValue(revertcolumn, value);
      value = !isNull(convertedValue) ? convertedValue : value;
    }
    return Number(value);
  }

  // convert from API value to unit system value and format precision and return as string
  convertDisplayValue(value: any, column: any): string {
    if (this.convertQualify(value, column)) {
      //let convertedValue = this.convertValue(value, column);
      let convertedValue = this._unitConversion.convertValue(column, value);
      let roundedvalue = convertedValue;
      if (column.decimalOption == "Default" || column.decimalOption == "Fixed Decimals") {
        roundedvalue = convertedValue.toFixed(column.decimalPrecision);
      }
      if (column.decimalOption == "Significant Digits") {
        roundedvalue = convertedValue.toPrecision(column.significantDigits);
      }
      value = roundedvalue ? roundedvalue : value;
    }
    return value;
  }

  // convertValue with conversion flag check and uom object detection
  convertValueIf(doConvert: boolean, value: any, column: any): number {
    if (this.isUomValue(value)) value = value.value;
    if (!value) value = 0;
    return Number(doConvert ? this.convertValue(Number(value), column) : value);
  }

  convertValueIfImport(doConvert: boolean, value: any, column: any): number {
    if (this.isUomValue(value)) {
      value = value.value;
    }

    if (!isNaN(value)) {
      value = Number(doConvert ? this.convertValue(Number(value), column) : value);
    }
    if (!value && isNull(value)) {
      var x = 0;
      //value = 0;
    }

    return value;
  }

  // revertValue with conversion flag check and uom object detection
  revertValueIf(doConvert: boolean, value: any, column: any): number {
    if (this.isUomValue(value)) value = value.value;
    return Number(doConvert ? this.revertValue(Number(value), column) : value);
  }

  revertValueIfImport(doConvert: boolean, value: any, column: any): number {
    if (this.isUomValue(value)) {
      value = value.value;
    }

    if (!isNaN(value)) {
      return Number(doConvert ? this.revertValue(Number(value), column) : value);
    }

    return value;
  }

  // converts and formats for display in form (used in dynamic data tables)
  displayValue_CurrentUnitSystem(value: any, colSettings: Array<any>, field: string): string {
    let units = this._unitConversion.currentUnitSystem$.value.name;
    var column = this.getColumn(colSettings, field);
    if (column && !column.fixedUnit) {
      let convertedValue = value === 0 ? 0 : (units === 'API') ? value : this.convertValue(value, column);
      let roundedvalue = this._uomConversion.calculateConvertedValueDisplay(column, convertedValue);
      value = roundedvalue ? roundedvalue : value;
    }
    return value;
  }

  // formatData_CurrentUnitSystem(data: Array<any>, colSettings: Array<any>, fields: Array<string>, reverse: boolean) {
  //   // loop fields to convert
  //   fields.forEach((field) => {
  //     var column = this.getColumn(colSettings, field);
  //     if (column && !column.fixedUnit) {
  //       if (reverse) {
  //         column.unit = column.currentUnit;
  //         column.currentUnit = "API";
  //       }
  //       // loop items in data
  //       data.forEach((item) => {
  //         let value = item[field].value;
  //         item[field].value = this.convertValue(value, column);
  //       });
  //     }
  //   });
  // }

  // check if unit conversion is necessary
  convertQualify(value: any, column: any): boolean {
    return ((value != 0 || column.quantity === 'Temperature') && column && !column.fixedUnit) ? true : false;
  }

}
