import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UomConversionService } from '../../core/services/uom-conversion.service';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PAGINATION_PROPERTIES } from 'src/app/utils/constants';
import * as OB from 'src/app/utils/objecter';

@Component({
  selector: 'app-h-table',
  templateUrl: './h-table.component.html',
  styleUrls: ['./h-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HTableComponent implements OnInit, OnChanges {
  @Input() tableName: string = '';
  @Input() headers: any = [];
  @Input() subHeaders: any = [];
  @Input() rows: any = [];
  @Input() enterCustom: boolean = false;
  @Input() download: boolean = false;
  @Input() searchField: boolean = false;
  @Input() data: any = [];
  @Input() newRow: boolean = false;
  @Input() icons: any;
  @Input() editable: boolean = false;
  @Input() headers2: any;
  @Input() maxHeight: string = '400px';
  @Input() striped: boolean = false;
  @Input() icon: boolean;
  @Input() fullTable: boolean = false;
  @Input() colSettings: any;
  @Input() tableWidth: string = '100%';
  @Input() rowPointer: boolean = true
  @Input() isBoundaryLinks: boolean = true; // If true, the "First" and "Last" page links are shown.
  @Input() isDirectionLinks: boolean = true; // If true, the "Next" and "Previous" page links are shown.
  @Input() pageSize = PAGINATION_PROPERTIES.ITEMS_PER_PAGE;
  @Input() maxFilterDisplayLength: number = 20;
  @Input() maxColumnDisplayLength: number = 20;
  @Input() showToolTips: number = 0;

  @Output() row: EventEmitter<any> = new EventEmitter<any>();
  @Output() customRow: EventEmitter<any> = new EventEmitter<any>();


  public PAGINATION_PROPERTIES = PAGINATION_PROPERTIES;

  public key: string;
  public keyNum: any;
  public forRows = [];
  public sortKey: string;
  public sortToggle: boolean = true;
  public isNum: boolean = false;
  public ogData: any = [];
  public sampleData: any = {};
  public unique: any = [];
  public uniqueDisplay: any = [];
  public filterValues: any = {};
  public tableHeader: string;
  public divWidth: number = 0;
  public newRowValue: any = {};
  public customRowValues: any = {};
  public searchText: string;
  public selectText: string;
  public showOverlay: boolean = false;
  public overlayLeft: any;
  public overlayHeader: string;
  public inputCount: any = [];
  public luColSettings: object = {};
  public myForm: FormGroup;

  public pageEvent = {
    itemsPerPage: this.pageSize,
    page: PAGINATION_PROPERTIES.DEFAULT_PAGE,
    startItem: PAGINATION_PROPERTIES.START_ITEM,
    endItem: this.pageSize
  }

  // Current paging data is displayed on html
  currentData: any;


  @ViewChild('filterSortOverlay') filterSortOverlay: OverlayPanel;
  @ViewChild('newEditableRow') public newEditableRow: any;
  @ViewChild('filterTable') public filterTable: ElementRef;
  @ViewChildren('filterIcons') public filterIcones: QueryList<ElementRef>;

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    // const targetElement = event.target as HTMLElement;
    // if (targetElement && this.filterIcones && this.filterTable 
    //       && !this.filterTable.nativeElement.contains(targetElement)) {
    //   for (let filterIcone of this.filterIcones.toArray()) {
    //     if (filterIcone && filterIcone.nativeElement.contains(targetElement)) {
    //       return;
    //     }
    //   }
    //   this.showOverlay = false;
    // }
  }


  constructor(
    public fb: FormBuilder,
    public _utilities: UtilitiesService,
    public _uomConversion: UomConversionService,
    public cdRef: ChangeDetectorRef,
  ) {
    this.myForm = this.fb.group({
      filter: this.fb.array([])
    });

  }

  ngOnInit() {
    this.currentData = this.getIndexItemData(this.pageEvent.startItem, this.pageSize);
    //NEEDS SOLUTION!
    // if (this.data && this.fullTable === false) {
    //   this.data = this.data.slice(0, 15);
    // }
    if (this.data) {
      this.getInputCount();
      this.manageInputRows();
      this.addRowIndex();
      this.ogData = this.data;
    }
  }

  ngOnChanges(dataChanges) {
    if(dataChanges.hasOwnProperty('data')){
      this.setDefaultData();
    }
    // reset to default when input data changed.
    if (this.data.length <= this.pageSize) {
      this.pageEvent.startItem = 0;
    }
    let endItem = this.pageEvent.page * this.pageSize;
    if (this.data.length < endItem) {
      endItem = this.data.length;
    }
    this.sortToggle = true;
    this.filterValues = [];

    this.currentData = this.getIndexItemData(this.pageEvent.startItem, endItem);
    this.getInputCount();
    this.manageInputRows();
    this.dismissOverlayMenu();
    this.addRowIndex();
    this.ogData = this.data;
    if (this.ogData != undefined) {
      this.sampleData = this.ogData[0];
    }
  }

  trackByFn(index, item) {
    return index;
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;

    this.pageEvent.page = event.page;
    this.pageEvent.itemsPerPage = event.itemsPerPage;
    this.pageEvent.startItem = startItem;
    this.pageEvent.endItem = endItem;

    this.currentData = this.getIndexItemData(startItem, endItem);
  }

  getInputCount() {
    for (let header of this.headers) {
      header.showToolTips = this.showToolTips;
      header.maxColumnDisplayLength = this.maxColumnDisplayLength;
      if (header.hideInput != true) {
        this.inputCount.push(0);
      }
      else {
        this.inputCount.push(1)
      }
    }
  }

  manageInputRows() {
    this.forRows = [];
    for (let i = 0; i < this.rows; i++) {
      this.forRows.push(i);
    }
  }

  // add row index to data so that emitted row can be located in original data list
  addRowIndex() {
    let ndx = 0;
    this.data.forEach(element => {
      element.rowIndex = ndx;
      ndx = ndx + 1;
    });
  }

  rowEvent(event, row, key) { 
    if( row[key] && (row[key]==='disabled' || row[key]==='hidden') && key.includes('Icon')){
      return;
    }

    let rowChecked = <HTMLInputElement>document.getElementById('checkbox-' + row.id);
    row['tableCellSelected'] = key;
    if (rowChecked !== null) {
      row['tableCheckbox'] = rowChecked.checked;
    }
    if (row.idx) {
      delete row.idx;
    }
    this.row.emit(row);
  }

  blurEvent(header, headers, index) {
    let inputValue: any = document.getElementById(header.key + '-' + this.tableName) as HTMLInputElement;
    let lastIndex = this.inputCount.lastIndexOf(0);
    this.customRowValues[header.key] = inputValue.value;

    if (index === lastIndex) {
      this.customRow.emit(this.customRowValues);
      for (header of headers) {
        let input = document.getElementById(header.key + '-' + this.tableName) as HTMLInputElement;
        input.value = '';
      }
    }
  }

  sort() {
    let key = this.key;
    let toggle = this.sortToggle;

    if (!this.data) { return false; }
    this.data.sort(function (a, b) {
      let genreA;
      let genreB;
      let typ = typeof (a[key]);

      if (typ != 'string') {
        if (a[key] && a[key].uom && typeof (a[key].uom === 'string')) {
          genreA = a[key].value;
          genreB = b[key].value;
        }
        else if (Array.isArray(a[key])) {
          genreA = a[key].length > 0 ? a[key][0] : '';
          genreB = b[key].length > 0 ? b[key][0] : '';
        }
        else {
          genreA = a[key];
          genreB = b[key];
        }
      } else {
        genreA = a[key].toUpperCase();
        genreB = b[key].toUpperCase();
      }

      let comparison = 0;
      if (genreA < genreB) {
        comparison = 1;
      } else if (genreA > genreB) {
        comparison = -1;
      }
      if (toggle) {
        this.sortToggle = false;
        return comparison;
      } else {
        this.sortToggle = true;
        return comparison * -1;

      }
    }.bind(this.data));
    this.currentData = this.getIndexItemData(this.pageEvent.startItem, this.pageEvent.endItem);
    this.sortToggle = !this.sortToggle;
    this.sortKey = key;
  }

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, columnDelimiter, lineDelimiter, data;
    var keys: any = [];
    data = args.data || null;
    if (data === null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';
    this.headers.filter(val => keys.push(val.key));

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) { result += columnDelimiter; }

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV(args) {
    var data, filename, link;
    var csv = this.convertArrayOfObjectsToCSV({
      data: this.data
    });
    if (csv === null) { return; }

    filename = this.tableName + ".csv" || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }

  deleteRow(e, t) {
    let index;
    if (this.enterCustom === true) {
      index = e.path[3].rowIndex - 2;
    } else {
      index = e.path[3].rowIndex - 1;
    }
    if (index !== -1) { this.data.splice(index, 1); }
    return true;
  }

  checkCell(event) {
    let cell = event.path[1].cellIndex;
    let cells = event.path[2].childElementCount - 2;
    if (cell === cells) {
      this.getNewRowValues(event);
    }
    return true;
  }

  getTableName() {
    return this.tableName;
  }

  styleValue(row, key) {
    if (row[key] === '' || row[key] === null || row[key] === undefined) {
      row[key] = '';
    }
    return row[key];
  }

  cellValue(row, key) {
    if (row[key] === undefined) {
      return '';
    }
    return row[key];
  }

  cellIconValue(row, key) {
    if (key === 'editIcon') {
      if (row[key] === "disabled") {
        return 'ic-edit-icon edit-btn icon-collumn disabled-btn';
      }
      if (OB.getObj(row, key) === null) {
        return 'filter-color-orange ic-edit-icon edit-btn icon-collumn';
      }
    }

    if (key === 'deleteIcon') {
      if (row[key] === "disabled") {
        return 'ic-close-icon delete-btn icon-collumn disabled-btn';
      }
      if (OB.getObj(row, key) === null) {
        return 'filter-color-orange ic-close-icon delete-btn icon-collumn';
      }
      return 'filter-color-orange ic-close-icon delete-btn icon-collumn';
    }

    if (key === 'viewIcon') {
      if (row[key] === "disabled") {
        return 'ic-clipboard-view clipboard-btn icon-collumn disabled-btn';
      }
      if (row[key] === "hidden") {
        return 'ic-clipboard-view clipboard-btn icon-collumn hidden-btn';
      }
      if (OB.getObj(row, key) === null) {
        return 'filter-color-orange ic-clipboard-view clipboard-btn icon-collumn';
      }
    }

    if (row[key] === '' || row[key] == null || row[key] == undefined) {
      row[key] = '';
    }

    return row[key];
  }

  // cellValueIsNum(row, header) {
  //   if (header.uom == false || header.uom == undefined) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // check if cell should be right aligned, typically a number
  isCellTextAlignRight(header) {
    if (header.type == 'number') {
      return true;
    }
    if (header.type == 'text') {
      return false;
    }
    let settings;
    // handle if no colSettings are passed to h-table
    if (this.colSettings !== undefined) {
      // check cache for presence
      if (this.luColSettings[header.key] !== undefined) {
        settings = this.luColSettings[header.key];
      } else {
        settings = this._utilities.getColumn(this.colSettings, header.key);
        //settings = this.colSettings.find(x => x.colName === header.key);
        this.luColSettings[header.key] = settings;
      }
    }
    if (settings !== undefined) {
      return settings.quantity.match(/unknown/i) ? false : true; // logic based upon column settings quantity
    } else {
      // for backwards compatibility if column settings are not passed
      return (header.uom === true);
      //return (header.uom === false || header.uom === undefined) ? false : true;
    }
  }

  // determines if value gets precisioned and converted
  // foundational logic same as isCellTextAlignRight, added to help with readability of html
  useColSettings(header) {
    return (header.format === false) ? false : this.isCellTextAlignRight(header);
  }

  getNewRowValues(event) {
    var rows: any = document.getElementById(this.tableName + "newRow");
    this.newRowValue = {};
    for (let i = 0; i < rows.children.length; i++) {
      this.newRowValue[rows.children[i].id] = rows.children[i].children[0].value;
      rows.children[i].children[0].value = null;
    }
    this.data.push(this.newRowValue);
    this.newEditableRow.nativeElement.children[0].focus();
  }

  deleteRowData() {
    var rows: any = document.getElementById("newRow");
    for (let i = 0; i < rows.children.length; i++) {
      rows.children[i].children[0].value = null;
    }
  }

  showData() {
    var tableData = [];
    let table: any;
    table = document.getElementsByName(this.tableName)[0];
    if (table !== undefined) {
      var rows = table.rows;
      for (var i = 0; i < rows.length; i++) {
        let rowData = [];

        for (var x = 0; x < rows[i].children.length; x++) {
          if (rows[i].children[x].childElementCount > 0) {
            rowData.push(rows[i].children[x].firstElementChild.value);
          } else {
            rowData.push(rows[i].children[x].innerHTML);
          }
        }
        tableData.push(rowData);
      }
    }
  }

  // not implemented
  selectAll(isChecked: boolean) {
    this.filterValues[this.key] = this.unique;
    this.transform();
  }

  clearAll(isChecked: boolean) {
    delete this.filterValues[this.key];
    this.transform();
  }

  onChange(item: string, isChecked: boolean) {
    // need to get original value not display value
    let indexOfDisplay = this.uniqueDisplay.indexOf(item);
    let value = indexOfDisplay != -1 ? this.unique[indexOfDisplay] : item;
    if (isChecked) {
      if (this.filterValues[this.key]) {
        this.filterValues[this.key].push(value);
      }
      else {
        this.filterValues[this.key] = [];
        this.filterValues[this.key].push(value);
      }
    }
    else {
      let indexOfValue = this.filterValues[this.key].indexOf(value);
      if (indexOfValue > -1) {
        this.filterValues[this.key].splice(indexOfValue, 1);
      }
      if (this.filterValues[this.key].length === 0) {
        delete this.filterValues[this.key];
      }
    }
    this.transform();
  }

  checkSelect(item) {
    if (this.filterValues[this.key]) {
      // need to get original value not display value
      let indexOfDisplay = this.uniqueDisplay.indexOf(item);
      let value = indexOfDisplay != -1 ? this.unique[indexOfDisplay] : item;
      if (this.filterValues[this.key].includes(value)) {
        return true;
      }
    }
    return false;
  }

  overlayMenuToggle(event, header) {
    let key = header.key;
    let title = header.title;
    let uom = header.uom;
    this.searchText = null;
    this.key = key;

    // to handle value/uom object (also done in cellValue pipe)
    let values = this.ogData.map(item => (item[key].value === undefined) ? item[key] : item[key].value);
    
    if (header.type === "array") {
      values = this.convertArrayValues(values);
    }

    // shorten string length of values (note: now doing it in view)
    this.unique = Array.from(new Set(values));
    //let uniqueIfShort = Array.from(new Set(values)).map(item => this.limitStringLength(item));
    //this.unique = Array.from(new Set(uniqueIfShort));

    if (uom == true) {
      this.unique.sort(function (a, b) { return a - b; });
      let col = this._utilities.getColumn(this.colSettings, key);
      this.uniqueDisplay = this.unique.map(item => this.calculateUoMCellValue(col, item, header));
    } else {
      this.unique.sort();
      this.uniqueDisplay = this.unique;
    }

    let newModalLeft = 0;
    const modalWidth = 230;
    const element = document.getElementById('header-' + key + this.tableName)
    if (this.divWidth === 0) {
      const head = element.parentElement.parentElement.parentElement.parentElement;
      this.divWidth = head.offsetWidth;
    }

    const headerLeft = element.parentElement.offsetLeft;
    let tableWidth = this.divWidth;

    if ((headerLeft + modalWidth) > tableWidth) {
      const pixelOvermax = (headerLeft + modalWidth) - tableWidth;
      newModalLeft = tableWidth - pixelOvermax - modalWidth;
    } else {
      newModalLeft = headerLeft;
    }

    this.overlayLeft = newModalLeft + 25 + 'px';
    this.sortToggle = this.sortKey === key ? false : true;
    this.showOverlay = true;
    this.overlayHeader = title;
  }

  convertArrayValues(data: any) {
    let res = [];
    for (let item of data) {
      if (Array.isArray(item)) {
        for (let child of item) {
          res.push(child);
        }
      }
    }

    return res;
  }

  dismissOverlayMenu() {
    this.showOverlay = false;
  }

  transform() {
    this.data = this.ogData.filter(row => {
      return this.isMatched(row, this.filterValues);
    });

    let size = this.data.length;
    if (size > this.pageEvent.itemsPerPage && size < this.pageEvent.startItem) {
      const totalPages = Math.ceil(size / this.pageEvent.itemsPerPage);
      this.pageChanged({ itemsPerPage: this.pageEvent.itemsPerPage, page: totalPages })
      return;
    }

    this.currentData = this.data.length > this.pageEvent.itemsPerPage ? this.getIndexItemData(this.pageEvent.startItem, this.pageEvent.endItem) : this.data;
  }

  isMatched(row, filters) {
    let keys = Object.keys(filters);

    for(let key of keys) {
      if (Array.isArray(row[key]) && row[key].length > 0) {
        let match = false;
        for (let child of row[key]) {
          if (filters[key].some(x=>x == child)) {
            match = true;
            break;
          }
        }
        if(match) {
          continue;
        }
      }
      const rowVal = OB.isUomObject(row[key]) ? row[key].value : row[key];
      if(filters[key].some(x=>x == rowVal)) {
        continue;
      }
      return false;
    }

    return true;
  }

  // modified to allow for optional convert and format using header properties
  calculateUoMCellValue(fixedDecimals, value, header) {
    let val = this._uomConversion.calculateValueDisplay(fixedDecimals, value, true, (header.format != false));
    return val;
  }

  public getIndexItemData(startItem, endItem) {
    return this.data ? this.data.slice(startItem, endItem) : [];
  }

  public limitFilterDisplayLength(item) {
    try {
      if (typeof item === 'string' && (item.length > this.maxFilterDisplayLength)) {
        item = item.substring(0, this.maxFilterDisplayLength - 3) + '...';
      }
    } catch (error) {
      return item;
    }
    return item;
  }

  private setDefaultData() {
    this.data.map(item => {
      this.headers.forEach(header => {
        let key = header.key ? header.key : '';
        if (!item.hasOwnProperty(key)) {
          if (header.type === 'array') item[key] = [];
          if (header.type === 'text') item[key] = '';
        }
      });
    });
  }
}
