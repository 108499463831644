import { ValidationExpression } from './../../shared/components/app-table-toolbar/table-toolbar.component';
import { DialogResult, DialogInput } from './../../shared/components/dialog/dialog.component';
import { DialogModalService } from './../../core/services/dialogModal.service';
import { Input, Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import * as OB from 'src/app/utils/objecter';
import { ACTION_TYPE, CATALOGS_NAME, FIELD_TYPE} from 'src/app/shared/enums/enums';
import { ToolbarColumn, ToolbarEvent, ToolbarSharing } from 'src/app/shared/components/app-table-toolbar/table-toolbar.component';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { CatalogsManagementService } from 'src/app/core/services/catalogs/catalogs-management.service';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';
import { takeUntil } from 'rxjs/operators';
import { Item } from 'src/app/core/models/common.model';
import * as LS from 'src/app/utils/localstorage';

export interface CatalogSetup {
  name: CATALOGS_NAME;
  collection?: string;
  property?: string;
  type?: string;
  children?: string;
  header?: string;
  addText?: string;
  importText?: string;
  columns?: Array<ToolbarColumn>;
  unitColSettings?: FieldLevelUoMSettings[];
  find?: Array<Filter>;
  hierachy?: Array<any>;
  isPureArray?: boolean;
  root?: string,
  deleteConfirmation?: Confirmation;
}

export interface Filter {
  key: string,
  deepFind?: string
}

export interface Confirmation {
  message: string,
  params?: Array<any>
}

export enum CatalogType {
  Array = "type_1",
  Object = "type_2"
}

@Component({
  selector: 'app-catalog-setup',
  templateUrl: './catalog-setup.component.html',
  styleUrls: ['./catalog-setup.component.scss']
})

export class CatalogSetupComponent implements OnInit, OnDestroy {

  @Input() public setting: CatalogSetup;
  @Output() public onOpenChildren = new EventEmitter<any>();
  @Output() public onCancel = new EventEmitter<any>();

  public pageLoaded: boolean = false;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public table: any = [];
  public header: string;
  public addText: string;
  public importText: string;
  public form: FormGroup;
  public importForm: FormGroup;
  public hasChildren: boolean;
  public hasValidatorsWhen: boolean;
  public hasUpdate: boolean;
  public hasDisableWhen: boolean;
  public hasSourceData: boolean;
  public handlingFormChange: boolean = false;
  public unitColSettings: FieldLevelUoMSettings[];
  public toolbarSharing: ToolbarSharing = new ToolbarSharing();
  public toolbarEvent$ = new BehaviorSubject<ToolbarEvent>({action: ACTION_TYPE.INIT});
  public columns: Array<ToolbarColumn>;
  public importModal: NgxSmartModalComponent;
  public firstColumn: ToolbarColumn;
  public confirmationType: string;
  public importData = [];
  public previewMode: boolean = false;
  public showBaseOilGraph: boolean = false;
  public baseOilGraph: any;
  public subHeader: string = "";
  public originData: any;
  public importConfirmation: DialogInput = {
    data: {
      body: this._utilities.getTranslationNow("fileImportConfirmation")
    },
    buttons: {
      cancel: true,
      ok: true
    }
  };

  public deleteConfirmation: DialogInput = {
    data: {
      body: "",
    },
    buttons: {
      cancel: true,
      ok: true
    }
  };

  constructor(
    public formBuilder: FormBuilder,
    public _utilities: UtilitiesService,
    public _catalogsManagement: CatalogsManagementService,
    public _ngxSmartModal: NgxSmartModalService,
    public _dialogModal: DialogModalService,
    public _unitConversion: UnitConversionService
  ) {
  }

  ngOnInit() {
    window.scroll(0,0);
    this.initialize();
    this.loadCatalog();
    this.loadAdditionalData();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  get validationDataFn() {
    return this.table.filter((el, idx) => idx != this.toolbarSharing.indexEditing);
  }
  get validationImportDataFn() {
    return this.importData;
  }
  get previewForms() { return <FormArray>(this.importForm.controls.previewForms)}
  get isObjectType() { return this.setting.type == CatalogType.Object }

  private findData(data) {
    if (this.setting.find && this.setting.hierachy) {
      let result = data;
      for(let param of this.setting.find) {
        const obj = this.setting.hierachy.find(x => x.key == param.key);
        if(obj) {
          const val = obj.value;
          result = result.find(x => x[param.key] == val);
          if(param.deepFind && result) {
            result = result[param.deepFind];
          }
        }
      }
      result = result ?? [];
      return result;
    }

    return data;
  }

  private updateTableData(data) {
    if(this.setting.isPureArray) {
      this.table = data.map(x=> {
        let obj = new Object();
        obj[this.firstColumn.key] = x;
        return obj;
      });
    } else {
      this.table = data;
    }
  }

  private loadCatalog() {
    this.loadSubHeader();
    this._utilities.showSpinner(true);
    this._catalogsManagement.getCatalog(this.setting.collection, this.setting.property).subscribe(res => {
      this.originData = OB.clone(res);
      res = this.findData(res);

      if (this.setting.name == CATALOGS_NAME.BaseOilPVT) {
        res = this.convertConstantsToDecimalString(res);
      }

      this.updateTableData(res);
      this._utilities.showSpinner(false);
    });
  }

  private convertConstantsToDecimalString(data: any) {
    return data.map(item => {
      return {
        ...item,
        constant1: OB.scientificToDecimalString(item.constant1),
        constant2: OB.scientificToDecimalString(item.constant2),
        constant3: OB.scientificToDecimalString(item.constant3),
        constant4: OB.scientificToDecimalString(item.constant4),
        constant5: OB.scientificToDecimalString(item.constant5),
      }
    });
  }

  private convertConstantsRowToDecimalString(data: any) {
    data.constant1 = OB.scientificToDecimalString(data.constant1);
    data.constant2 = OB.scientificToDecimalString(data.constant2);
    data.constant3 = OB.scientificToDecimalString(data.constant3);
    data.constant4 = OB.scientificToDecimalString(data.constant4);
    data.constant5 = OB.scientificToDecimalString(data.constant5);
    return data;
  }

  private convertConstantsRowToNumber(data: any) {
    data.constant1 = Number(data.constant1);
    data.constant2 = Number(data.constant2);
    data.constant3 = Number(data.constant3);
    data.constant4 = Number(data.constant4);
    data.constant5 = Number(data.constant5);
    return data;
  }

  private loadSubHeader() {
    if(this.setting.hierachy) {
      for(const data of this.setting.hierachy) {
        if(this.subHeader) {
           this.subHeader = this.subHeader  +   " / "   + data.value;
        } else {
          this.subHeader = data.value;
        }
      }
    }
  }

  private loadAdditionalData() {
    let reqs = [];
    let queries = this.columns.filter(x=> OB.isNull(x.queryData) == false);
    queries.forEach(x=> {
      reqs.push(this._catalogsManagement.getCatalogItem(x.queryData));
    });

    if(reqs.length > 0) {
      this._utilities.showSpinner(true);
      combineLatest(reqs).pipe(takeUntil(this.componentDestroyed$)).subscribe(results => {
        this._utilities.showSpinner(false);
        const emptyObj = {};
        for(let i = 0; i < results.length; i++) {
          const defaultItems = queries[i].defaultItems ?? [];
          const result = results[i] as Array<Item>;;
          queries[i].data = defaultItems.concat(result);
          emptyObj[queries[i].key] = null;
          const sort = queries[i].queryData.sort;
          if (sort) {
            OB.sortArr(queries[i].data, "label", sort);
          }
        }

        setTimeout(() => {
          this.form.patchValue(emptyObj); //reset dropdown data
        }, 0);
      });
    }
  }

  private initialize() {
    if(this.setting) {
      this.header = this.setting.header;
      this.addText = this.setting.addText;
      this.importText = this.setting.importText;
      this.columns = this.setting.columns;
      this.hasChildren = this.setting.children ? true: false;
      this.unitColSettings = this.setting.unitColSettings;
      this.firstColumn = this.setting.columns[0];
      this.hasValidatorsWhen = this.columns.filter(x=>x.validatorsWhen != null)[0] ? true: false;
      this.hasDisableWhen = this.columns.filter(x=>x.disableWhen != null)[0] ? true: false;
      this.hasSourceData = this.columns.find(x=>x.sourceData != null) ? true: false;
      this.initializeForm();
      this.updateValidationWhen(this.form);
      this.listenFormChanges();
    }
  }

  public handleAdd(event){
    const { action, originDataUom, index, data } = event;
    let payload = action == ACTION_TYPE.CREATE ? this.revertObj(data) : originDataUom;

    if (this.setting.name == CATALOGS_NAME.BaseOilPVT) {
      payload = this.convertConstantsRowToNumber(payload);
    }

    const req = this.buildRequest(action, index, payload);
    this._utilities.promiseAll([req], ["Catalog"], action).then(success => {
      if(success) {
        this.hasUpdate = true;
        if(action == ACTION_TYPE.CREATE && this.isObjectType && !this.setting.find) {
          payload = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Data.CatalogSetup.CreateUpdate);
          LS.removeLocalStorage(LS.LOCAL_STORAGE_KEY.Data.CatalogSetup.CreateUpdate);
        }

        if(this.setting.name == CATALOGS_NAME.Country) {
          const oldName = action == ACTION_TYPE.UPDATE ? this.table[index].country : null;
          const newName = data.country;
          this._catalogsManagement.updateCatalogCountry(oldName, newName, action).subscribe();
        }
      }

      if (this.setting.name == CATALOGS_NAME.BaseOilPVT) {
        payload = this.convertConstantsRowToDecimalString(payload);
      }
      this.toolbarEvent$.next({ action: action, status: success, data: payload });
    });
  }

  public handleEdit(event){
    //reset for disable control
    if(this.hasDisableWhen) {
      this.form.patchValue(event.data);
    }
    window.scroll(0,0);
  }

  public handleDelete(event) {
    const { originDataUom } = event;
    const confirmationSetting = this.setting.deleteConfirmation;
    if (confirmationSetting) {
      this.deleteConfirmation.data.body = this.buildConfirmMessage(confirmationSetting, originDataUom);
      this._dialogModal.showConfirmation(this.deleteConfirmation).subscribe(res => {
        if(res === DialogResult.Ok) {
          this.postDelete(event);
        } else {
          this.toolbarEvent$.next({ action: ACTION_TYPE.DONE });
        }
      });
      return;
    }

    this.postDelete(event);
  }

  private buildConfirmMessage(confirmationSetting: Confirmation, row?) {
    let message;
    if (confirmationSetting) {
      message = confirmationSetting.message;
      if(confirmationSetting.params) {
        for(let i = 0; i < confirmationSetting.params.length; i++) {
          const search = '{{param' + i.toString() + '}}';
          const param = confirmationSetting.params[i];
          let value = param;

          if(param.includes("column_")) {
            const key = param.split("_")[1];
            const column = this.columns.find(x=> x.key == key);
            const path = column.path ?? column.key;
            value = OB.getValue(row, path);
          }

          message = message.replace(search, value);
        }
      }
    }

    return message;
  }

  private postDelete(event) {
    const { originDataUom, index } = event;
    const action = ACTION_TYPE.DELETE;
    const req = this.buildRequest(action, index, originDataUom);

    this._utilities.promiseAll([req], ["Catalog"], action).then(success => {
      this.toolbarEvent$.next({ action: action, status: success });
      if (success) {
        this.hasUpdate = true;

        if(this.setting.name == CATALOGS_NAME.Country) {
          const oldName = originDataUom.country;
          this._catalogsManagement.updateCatalogCountry(oldName, null , action).subscribe();
        }
      }
    });
  }

  private updateObjIf(obj) {
    for (let col of this.columns) {
      if (col.updatePayloadWhen) {
        this.updatePayloadWhen(obj, col);
      }

      let path = col.path ? col.path : col.key;
      if(col.removeUom) {
        path = path.replace(".value", "");
        let val = OB.getObj(obj, path);
        OB.updateObj(obj, path, OB.removeUom(val));
      }

      if(col.removeIfNull && OB.isNull(OB.getObj(obj, path))) {
        OB.removeObj(obj, col.key);
      }
    }
  }

  private updatePayloadWhen(obj, col) {
    const andExpressions = col.updatePayloadWhen.andExpressions;
    const orExpressions = col.updatePayloadWhen.orExpressions;
    const colPath = col.path ? col.path : col.key;

    if (andExpressions) {
      for (const expression of andExpressions) {
        const expressionCol = this.columns.find(x => x.key == expression.key);
        const expressionPath = expressionCol.path ? expressionCol.path : expressionCol.key;
        const compareVal = OB.getObj(obj, expressionPath);
        const expressionVal = expression.value;

        if (!OB.compare(expression.condition, compareVal, expressionVal)) {
          return;
        }
      }

      OB.updateObj(obj, colPath, col.updatePayloadWhen.value);
    }

    if (orExpressions) {
      for (const expression of orExpressions) {
        const expressionCol = this.columns.find(x => x.key == expression.key);
        const expressionPath = expressionCol.path ? expressionCol.path : expressionCol.key;
        const compareVal = OB.getObj(obj, expressionPath);
        const expressionVal = expression.value;

        if (OB.compare(expression.condition, compareVal, expressionVal)) {
          OB.updateObj(obj, colPath, col.updatePayloadWhen.value);
          return;
        }
      }
    }
  }

  private buildRequest(action, index, data) {
    let payload;
    if(this.isObjectType) {
      this.updateObjIf(data);
      payload =  this.buildPayloadObject(action, data, index);
    }
    else {
      payload = this.buildPayloadArray(action, index, data);
    }

    return this._catalogsManagement.updateCatalog(payload);
  }

  private buildPayloadObject(action, data, index?) {
    let row = data;
    let items = null;
    if (this.setting.find && this.setting.hierachy) {
      for(let i = 0;  i < this.setting.find.length; i++)
      {
        const key = this.setting.find[i].key;
        const deepFind = this.setting.find[i].deepFind;
        const compareVal = this.setting.hierachy.find(x => x.key == key).value;
        if(i == 0) {
          row = this.originData.find(x => x[key] == compareVal);
          items = row;
        } else {
          items = items.find(x => x[key] == compareVal);
        }
        items[deepFind] = items[deepFind] ?? [];
        items = items[deepFind];
      }

      const item = this.setting.isPureArray ? data[this.firstColumn.key]: data;
      if(action == ACTION_TYPE.CREATE) {
        items.push(item);
      } else if(action == ACTION_TYPE.UPDATE) {
        items[index] = item;
      } else if(action == ACTION_TYPE.DELETE) {
        items.splice(index, 1);
      }
    }

    return {
      collectionName: this.setting.collection,
      type: CatalogType.Object,
      action: (this.setting.find && this.setting.hierachy) ? ACTION_TYPE.UPDATE: action,
      data: row
    };
  }

  private buildPayloadArray(action, index, data?) {
    let table = OB.clone(this.table);
    let item = data;
    if(this.setting.isPureArray) {
      table = table.map(x=> { return x[this.firstColumn.key];});
      item = data[this.firstColumn.key];
    }

    if(action == ACTION_TYPE.CREATE) {
      table.push(item);
    } else if(action == ACTION_TYPE.UPDATE) {
      table[index] = item;
    } else if(action == ACTION_TYPE.DELETE) {
      table.splice(index, 1);
    }

    return {
      collectionName: this.setting.collection,
      type: CatalogType.Array,
      property: this.setting.property,
      action: action,
      data: table
    };
  }

  private buildRequestMany(action, data) {
    if(this.isObjectType) {
      const payload  = this.buildPayloadObject(action, data);
      return this._catalogsManagement.createMany(payload);
    } else {
      const payload = this.buildPayloadArrayMany(action, data);
      return this._catalogsManagement.createMany(payload);
    }
  }

  private buildPayloadArrayMany(action, data) {
    let table = OB.clone(data);
    if(this.setting.isPureArray) {
      table = table.map(x=> { return x[this.firstColumn.key];});
    }

    return {
      collectionName: this.setting.collection,
      type: CatalogType.Array,
      property: this.setting.property,
      action: action,
      data: table
    };
  }



  public createManyRecords(){
    const data = this.revertImportData(this.importData);
    const action = ACTION_TYPE.CREATE;
    const req = this.buildRequestMany(action, data);

    this._utilities.promiseAll([req], ["Catalog"], action).then(success => {
      if(success) {
        this.hasUpdate = true;
        this.loadCatalog();
        this.clearImport();
        this.toolbarEvent$.next({ action: ACTION_TYPE.RESET });
      }
    });
  }

  private revertImportData(data) {
    let results = [];
    for(let row of data) {
      let revert = this.revertObj(row);
      this.updateObjIf(revert);
      results.push(revert);
    }

    return results;
  }

  private revertObj(data) {
    let obj = {};
    for(let col of this.columns) {
      let path = col.path ? col.path: col.key;
      let val = data[col.key];
      let unitColumn = OB.getColumn(this.unitColSettings, col.key);
      if(col.convert && unitColumn) {
        val = this._unitConversion.revertValue(unitColumn, val);
      }
      OB.updateObj(obj, path, val);
    }

    return obj;
  }

  public resetTopRow() {
  }

  public submitImport(event) {
    let {data} = event;
    this.previewMode = true;
    this.importData = data;
    this.initializePreviewForm(data);
  }

  public clearImport() {
    this.previewMode = false;
    this.importData = [];
  }

  public approveImport(){
    if (this.table.length > 0) {
      this._dialogModal.showConfirmation(this.importConfirmation).subscribe(res => {
        if(res === DialogResult.Ok) {
          this.createManyRecords();
        }
      });
    } else {
      this.createManyRecords();
    }
  }

  public openImportModal() {
    this.importForm = this.formBuilder.group({
      previewForms: this.formBuilder.array([]),
    });
    this.importModal = this._ngxSmartModal.getModal('catalogImportModal');
    this.importModal.open();
  }

  private initializePreviewForm(data) {
    for(let row of data) {
      let val, obj = {}, validators;
      for(let column of this.columns) {
        val = OB.getObj(row, column.key, column.fieldType);
        validators = this.buildFormArrayValidators(column, row);
        if(column.disableWhen) {
          let disableValue = row[column.disableWhen.key];
          if(OB.compare(column.disableWhen.condition, disableValue, column.disableWhen.value)) {
            validators = [];
            val = null;
          }
        }
        obj[column.key] = [val, validators];
      }

      this.previewForms.push(this.formBuilder.group(obj));
    }
  }

  private toArrayValidators(validators = []) {
    for(let i = 0; i < validators.length; i++) {
      if(validators[i] == "notDuplicate") {
        validators[i] = "notDuplicateArray";
      }
    }
    return validators;
  }

  public handleConfig(event) {
    const {originDataUom} = event;

    if(this.setting.children == "baseFluidOilGraph") {
      this.showBaseOilGraph = false;
      setTimeout(()=> {
          this.baseOilGraph = originDataUom;
          this.showBaseOilGraph = true;
        }, 0);

      return;
    }

    this.onOpenChildren.emit({
      parentSetting: this.setting,
      data: originDataUom
    });

  }

  public cancel() {
    this.onCancel.emit({
      hasUpdate: this.hasUpdate
    });
  }

  cancelBaseOil() {
    this.showBaseOilGraph = false;
  }

  private initializeForm() {
    let val, controls = {}, validators;
    for(const col of this.columns) {
      val = OB.defaultValue(col.fieldType);
      validators = col.validators ? col.validators : [];
      controls[col.key] = [val, this.validatorsFn(validators)];
    }
    this.form = this.formBuilder.group(controls);
  }

  private listenFormChanges() {
    this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      //if(this.form.dirty) {
        if(this.handlingFormChange == false) {
          this.handlingFormChange = true;
          if(this.hasDisableWhen) {
            this.updateDisableWhen(this.form);
          }
          if(this.hasValidatorsWhen) {
            this.updateValidationWhen(this.form);
          }
          if(this.hasSourceData) {
            this.updateWhenChanges(this.form);
          }
          this.handlingFormChange = false;
        }
      //}
    });
  }

  private updateDisableWhen(form) {
    for(let col of this.columns) {
      const disableWhen = col.disableWhen;
      if(disableWhen) {
        const value = form.controls[col.disableWhen.key].value;
        const control = form.controls[col.key] as FormControl;
        const defaultVal = col.fieldType == FIELD_TYPE.NUMBER ? 0 : null;
        if(OB.compare(disableWhen.condition, value, disableWhen.value)) {
          control.patchValue(null, {emitEvent: false});
          control.disable({emitEvent: false});
        } else if(control.disabled) {
          control.patchValue(defaultVal, {emitEvent: false});
          control.enable({emitEvent: false});
        }
      }
    }
  }

  private updateWhenChanges(form) {
    for(let col of this.columns) {
      if(col.sourceData) {
        const sourceColumn = this.columns.find(x => x.key == col.sourceData);
        const path = sourceColumn.path ?? sourceColumn.key;
        const compareVal = OB.getValue(form.value, path);
        const sourceData = sourceColumn.data ?? [];
        const found = sourceData.find((x: Item) => x.value == compareVal) as any;
        const defaultItems = col.defaultItems ?? [];
        const sourceDataChild = found ? found.child: [];
        col.data = defaultItems.concat(sourceDataChild);
      }
    }
  }

  private updateValidationWhen(form) {
    for(let col of this.columns) {
      if(col.validatorsWhen) {
        let validators = col.validators ? [...col.validators] : [];
        let validationControl = form.controls[col.key] as FormControl;
        for(const expression of col.validatorsWhen) {
          const expressionControl = form.controls[expression.key];
          this.concatVaidatorsWhen(validators, expression, expressionControl.value);
        }
        console.log("validators", col.key, validators);
        validationControl.setValidators(this.validatorsFn(validators));
        validationControl.updateValueAndValidity();
      }
    }
  }

  private concatVaidatorsWhen(validators: Array<string>, expression: ValidationExpression, curValue) {
    if(expression.condition == "=" && OB.compareEqual(expression.value, curValue)) {
      if(expression.type == "remove") {
        this.removeValidator(validators, expression.validators);
      } else {
        this.concatValidator(validators, expression.validators);
      }
    }
    if(expression.condition == "!=" && OB.compareDiff(expression.value, curValue)) {
      if(expression.type == "remove") {
        this.removeValidator(validators, expression.validators);
      } else {
        this.concatValidator(validators, expression.validators);
      }
    }
  }

  private removeValidator(source: Array<string>, destination: Array<string>) {
    for(let validator of destination) {
      let foundIdx = source.indexOf(validator);
      if(foundIdx > -1) {
        source.splice(foundIdx, 1);
      }
    }
  }

  private concatValidator(source: Array<string>, destination: Array<string>) {
    for(let validator of destination) {
      if(source.includes(validator) == false) {
        source.push(validator);
      }
    }
  }

  private buildFormArrayValidators(column: ToolbarColumn, formValues) {
    let results;
    let validators = column.validators ? [...column.validators] : [];
    const validatorsWhen = column.validatorsWhen ? column.validatorsWhen : [];

    for(const expression of validatorsWhen) {
      let value = formValues[expression.key]
      this.concatVaidatorsWhen(validators, expression, value);
    }

    results = this.validatorsFn(this.toArrayValidators(validators));
    if(column.fieldType == FIELD_TYPE.DROPDOWN
      && OB.isNull(formValues[column.key]) == false
      && column.data && column.data.length > 0) {
      let data = column.data;
      if(data[0].hasOwnProperty("value")) {
        data = column.data.map(x=> {return x["value"]});
      }
      results.push(ValidatorsEx.mustBeInList(data));
    }

    return results;
  }

  private validatorsFn(validators = []) {
    let res = [];
    for(let validator of validators) {
      switch(validator){
        case "required":
          res.push(Validators.required);
          break;
        case "requiredText":
            res.push(ValidatorsEx.requiredText());
            break;
        case "negative":
          res.push(ValidatorsEx.negative())
          break;
        case "notDuplicate":
          res.push(ValidatorsEx.notDuplicate(this, "validationDataFn"))
          break;
        case "notDuplicateArray":
          res.push(ValidatorsEx.notDuplicateArray(this, "validationImportDataFn"))
          break;
        case "alphaNumberic":
          res.push(ValidatorsEx.mustBeAlphaNumeric())
          break;
      }

      if(validator.includes("maxLength")) {
        const len = Number.parseInt(validator.split("_")[1]);
        res.push(ValidatorsEx.maxLength(len));
      }

      if(validator.includes("alphaNumbericAnd")) {
        const val = validator.replace("alphaNumbericAnd_","");
        const specials = val.split("_");
        res.push(ValidatorsEx.mustBeAlphaNumericAnd(specials));
      }
    }

    return res;
  }

}


