import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { BehaviorSubject, ObservableLike } from 'rxjs';
import { MODAL_IDENTIFIERS } from '../../enums/enums';
import { Injectable } from '@angular/core';
import { Item } from 'src/app/core/models/common.model';

export interface DialogInput {
  data: {
    header?: string,
    body?: string,
    value?: any;
    options?: Array<Item>;
  },
  buttons: {
    ok: boolean,
    cancel: boolean,
    manual?: boolean,
    next?: boolean,   
  },
  continue?: {
    manual?: boolean,
    auto?: boolean
  }
};

export enum DialogResult {
  Ok = "Ok",
  Cancel = "Cancel",
  Manually = "Manually",
  Automatically = "Automatically"
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
 
  public MODAL_IDENTIFIERS = MODAL_IDENTIFIERS;
  public dialogInput: DialogInput;
  public showModal = false;
  public currentRadioValue = null;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() { }

  onOpenModal() {  
    setTimeout(() => {
      this.dialogInput = this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).getData();
      this.currentRadioValue = null;
      this.showModal = true;
    })
  }

  closeModal() {
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
    this.showModal = false;
  }

  cancel() {
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).onClose.emit(DialogResult.Cancel);
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
    this.showModal = false;
  }

  ok() {
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).onClose.emit(DialogResult.Ok);
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
    this.showModal = false;
  }

  next() {
    if(!this.currentRadioValue) {
      return;
    }
    let res = {
      action: DialogResult.Ok,
      value: this.currentRadioValue
    }
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).onClose.emit(res);
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
    this.showModal = false;
  }

  manual() {
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).onClose.emit(DialogResult.Manually);
    if(!this.dialogInput.continue?.manual) {
      this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
      this.showModal = false;
    }
  }

  auto() {
    this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).onClose.emit(DialogResult.Automatically);
    if(!this.dialogInput.continue?.auto) {
      this.ngxSmartModalService.getModal(MODAL_IDENTIFIERS.DIALOG).close();
      this.showModal = false;
    }
  }

  radioChange(event) {
    this.currentRadioValue = event.target.value;
  }
}
