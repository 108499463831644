import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { UserModel } from '../../core/models/userModel';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';
import { UnitSystem } from '../models/uom-models';
import { UnitConversionService } from '../..//core/services/unit-conversion.service';
import { PARAM_MAP, SAVE_DATA, UNUSED_QUANTITY_UNITS } from 'src/app/utils/constants';
import { CryptoUtils } from 'msal';
import { UnitSystemsService } from 'src/app/core/services/unitsystems.service';
import { API_RESPONSE, CHARACTERS, MODAL_IDENTIFIERS, TOASTER_TYPE, UNITS } from '../enums/enums';

@Component({
  selector: 'app-uom-modal',
  templateUrl: './uom-modal-component.component.html',
  styleUrls: ['./uom-modal-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UomModalComponentComponent implements OnInit {
  @Input() uomModalType: string;
  //@Output() onchange = new EventEmitter();
  //@ViewChild(NgForm, { static: true }) customOemForm;
  //@ViewChild('enterCustomSetting') enterCustomSetting: ElementRef;
  //@ViewChild('modalBody') modalBody: ElementRef;
  @ViewChild("uomModal") uomModal: NgxSmartModalComponent;

  public pageHeader: string = "";
  public unusedUnits: string[] = UNUSED_QUANTITY_UNITS;
  public jobId: string;
  public customUOMname: string;
  public initName: string;
  public initNames: any;
  public initQuantities: string;
  public popupMessage: string;
  public selectedBaseSystemId: string;
  public selectedUnitSystemId: string;
  public showCustomDelete: boolean;
  public showCustomCreate: boolean;
  public showCustomEdit: boolean;
  public showMainSelect: boolean;
  public uomFormDisabled: boolean = false;
  public uomModalReady: boolean;
  public user: UserModel;
  public setActive: boolean = true;
  public isReadOnly: boolean = false;
  public isChangedQuantity: boolean = false;
  public isChangedName: boolean = false;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public _ngxSmartModal: NgxSmartModalService,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _unitConversion: UnitConversionService,
    public route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  initializeSettings() {
    this.initName = this._unitSystems.selectedUnitSystem.name;
    this.initNames = this.initializeNames();
    this.initQuantities = this.initializeQuantities();
    this.isChangedQuantity = false;
  }

  initializeNames(): any {
    return this._unitSystems.unitSystems.map(x => x.name.toUpperCase());
  }

  initializeQuantities(): string {
    return JSON.stringify(this._unitSystems.selectedUnitSystem.quantities.map(x => x.selectedUnit));
  }

  getUrlJobId() {
    // jobId may or may not exist
    //let job = this._utilities.jobId$.value;
    this.jobId = this.route.snapshot.paramMap.get(PARAM_MAP.JOB_ID);
    return this.jobId
  }

  getModalData() {
    setTimeout(() => {
      let data = this.uomModal.getData();
      this._ngxSmartModal.resetModalData("uomModal");
      this.uomModalType = data.uomModalType;
      this.loadUOMModal();
    }, 0);
  }

  // loaded from template
  loadUOMModal() {
    this.showCustomCreate = false;
    if (this.uomModalType == "job") {
      this.getUrlJobId();
      this._unitSystems.selectedUnitSystem = this._unitSystems._unitConversion.currentUnitSystem$.value;
      this.pageHeader = this._utilities.getTranslationNow('unitSystemsProfile.pageheader_job');
    }

    if (this.uomModalType == "create") {
      this.uomModalType = "user";
      this.showCustomCreate = true;
    }

    if (this.uomModalType == "user") {
      this.selectedBaseSystemId = UNITS.API_ID;
      this._unitSystems.selectedUnitSystem = this._unitSystems.globalUnitSystem;
      this.pageHeader = this._utilities.getTranslationNow('unitSystemsProfile.pageheader_user');
    }

    this.loadUnitSystems();
    this.uomFormDisabled = true;
    this.showMainSelect = true;
  }

  loadUnitSystems() {
    this.selectedUnitSystemId = this._unitSystems.selectedUnitSystem.unitSystemId;
    if (this.selectedUnitSystemId != undefined) {
      this.showMainSelect = !this.showCustomCreate;
      this.setSelectedUnitSystem(this.selectedUnitSystemId);
      this.initializeSettings();
      if (this.showCustomCreate) this.pageHeader = this._utilities.getTranslationNow('unitSystemsProfile.pageheader_create');
      }
  }

  // dropdown to select unitsystem name
  setSelectedUnitSystem(id: string): void {
    this.isReadOnly = (this.uomModalType == "job" || id === UNITS.API_ID || id === UNITS.SI_ID) ? true : false;
    this.showCustomDelete = (!this.isReadOnly && !this.showCustomCreate && this.selectedUnitSystemId) ? true : false;
    this.setSelectedValue();
  }

  setSelectedValue(): void {
    if (this.showCustomCreate) {
      this.customUOMname = "";
      this._unitSystems.setSelectedUnitSystem(this.selectedBaseSystemId);
    }
    else {
      this._unitSystems.setSelectedUnitSystem(this.selectedUnitSystemId);
      if (this.selectedUnitSystemId === "_delete") {
        this.customUOMname = this._utilities.getTranslationNow('unitSystemsProfile.clearCustom');
      }
      else {
        this.customUOMname = this._unitSystems.selectedUnitSystem.name;
      }
      this.uomModalType == "user" && this.initializeSettings();
    }
    this.uomModalReady = true;
  }

  onQuantitySelected() {
    let currentQuantities = this.initializeQuantities();
    this.isChangedQuantity = (this.initQuantities != currentQuantities);
  }

  mapUnitSystemModel(): UnitSystem {
    let unitSystemModel = new UnitSystem();
    unitSystemModel.unitSystemUserId = this._utilities.currentUser.userId;
    unitSystemModel.unitSystemId = this.showCustomCreate ? CryptoUtils.createNewGuid() : this._unitSystems.selectedUnitSystem.unitSystemId;
    unitSystemModel.name = this.customUOMname;
    //unitSystemModel.name = this.showCustomCreate ? this.customUOMname : this._unitSystems.selectedUnitSystem.name;
    unitSystemModel.quantities = this._unitSystems.selectedUnitSystem.quantities;
    unitSystemModel.isActive = true;
    this._unitSystems.selectedUnitSystem.name = unitSystemModel.name;
    return unitSystemModel;
  }

  async createUpdateUnitSystem() {
    if (this.uomModalType == "job") {
      var mode = "assigned";
      let id = this._unitSystems.selectedUnitSystem.unitSystemId;
      let jobid = this._utilities.jobId$.value;
      var res = await this._unitSystems.setActiveUnitSystem(id, this._utilities.currentUser.userId, jobid);
      if (res === API_RESPONSE.SUCCESS) {
        var msg = this._utilities.getTranslationNow2('toastr.item' + mode, { item: 'Unit System' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
      }
      else {
        var msg = this._utilities.getTranslationNow2('toastr.itemnotupdated', { item: 'Unit System' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
      }
      this.closeUOMModal();
    }
    else {
      let unitSystemModel: any = this.mapUnitSystemModel();
      var id = unitSystemModel.unitSystemId;
      var mode = this.showCustomCreate ? "created" : "updated";
      // save button should not ve enabled, this is a fail-safe check
      if (mode === "created" && (id === UNITS.API_ID || id === UNITS.SI_ID)) {
        msg = this._utilities.getTranslationNow2('toastr.itemcannotcreate', { item: 'API or SI' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        return;
      }
      var res = await this._unitSystems.createUpdateUnitSystem(unitSystemModel);
      if (res === API_RESPONSE.SUCCESS) {
        var msg = this._utilities.getTranslationNow2('toastr.item' + mode, { item: 'Unit System' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
        // add/update to unitsystems array
        if (mode === "created") {
          this._unitSystems.unitSystems.push(unitSystemModel);
        }
        else {
          var item = this._unitSystems.findUnitSystem(id);
          var index = this._unitSystems.unitSystems.indexOf(item);
          if (index !== -1) this._unitSystems.unitSystems[index] = unitSystemModel;
          this._utilities.currentUser.settings.unitSystems = this._unitSystems.unitSystems;
        }
        this._unitSystems.sortUnitSystems();
        this._unitSystems.setSelectedUnitSystem(id);
        // always set active
        if (this.setActive) {
          this._unitSystems.setGlobalUnitSystem(id);
          this._utilities.currentUser.settings.activeUnitSystemId = id;
          this._unitSystems.setActiveUnitSystem(id, this._utilities.currentUser.userId, '');
        }
        // trigger observabvle to update settings page
        this._utilities.userModelObj$.next(this._utilities.currentUser);
        this.closeUOMModal();
      }
      else {
        var msg = this._utilities.getTranslationNow2('toastr.itemnotupdated', { item: 'Unit System' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
      }
    }
  }

  async deleteUnitSystemConfirm() {
    var msg = "";
    var id = this._unitSystems.selectedUnitSystem.unitSystemId;
    // delete icon should not appear, this is a fail-safe check
    if (id === UNITS.API_ID || id === UNITS.SI_ID) {
      msg = this._utilities.getTranslationNow2('toastr.itemcannotdelete', { item: 'API or SI' });
      this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
      return;
    }

    var jobs = this._unitSystems.getJobUnitSystemsByUnitSystemId(id);
    msg = this._utilities.getTranslationNow('unitSystemsProfile.deleteConfirmation');
    msg = msg + CHARACTERS.newline + '"' + this._unitSystems.selectedUnitSystem.name + '"';
    if (jobs && jobs.length > 0) {
      msg = msg + CHARACTERS.newline + CHARACTERS.newline + this._utilities.getTranslationNow2('unitSystemsProfile.deleteConfirmationJobs', { qty: jobs.length }, false);
    }
    this.popupMessage = msg;
    this.showConfirmPopup();
  }

  async deleteUnitSystem(id: string) {
    var msg = "";
    var res = await this._unitSystems.deleteUnitSystem(id, this._utilities.currentUser.userId);
    if (res === API_RESPONSE.SUCCESS) {
      msg = this._utilities.getTranslationNow2('toastr.itemdeleted', { item: 'Unit System' });
      this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
      // trigger observabvle to update settings page
      this._utilities.userModelObj$.next(this._utilities.currentUser);
      this.loadUOMModal();
    }
    else {
      msg = this._utilities.getTranslationNow2('toastr.itemnotdeleted', { item: 'Unit System' });
      this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
    }
  }

  showConfirmPopup() {
    this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.CONFIRM_POPUP).open();
  }

  async handleConfirmation() {
    var id = this._unitSystems.selectedUnitSystem.unitSystemId;
    await this.deleteUnitSystem(id);
  }

  closeUOMModal() {
    this.uomModal.close();
    this.uomModalReady = false;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  quantityUnits(Quantity) {
    let quantity = this._unitSystems.catQuantityUnits.find(x => x.quantity === Quantity.quantity);
    return quantity ? quantity.units : null;
  }

  // custom unitsystem name validations
  get isUnitSystemNameError() {
    return (
      ((this.uomModalType != 'job') && ((this.isUnitSystemNameErrorEmpty || this.isUnitSystemNameErrorExists)))
    );
  }

  get isUnitSystemNameErrorEmpty() {
    return (this.uomModalType != 'job') && !this.customUOMname;
  }

  get isUnitSystemNameErrorExists() {
    // short circuit error check
    this.isChangedName = false;
    if (!this.customUOMname || (this.uomModalType === 'job')) return false;
    if (!this.showCustomCreate && this.customUOMname.toUpperCase() === this.initName.toUpperCase()) return false;
    let name = this.customUOMname.toUpperCase();
    var exists = this.initNames.includes(name);
    var selected = this._unitSystems.selectedUnitSystem.name.toUpperCase();
    this.isChangedName = (name != selected);
    return exists && (this.showCustomCreate || this.isChangedName);
  }

  get isSaveDisabled() {
    var res = true;
    if (this.uomModalType === 'job') {
      res = (this.customUOMname === this.initName);
    } 
    else if (this.uomModalType === 'user') {
      res = (this.isUnitSystemNameError || (!this.isChangedQuantity && !this.isChangedName));
    }
    else if (this.uomModalType === 'create') {
      res = this.isUnitSystemNameError;
    }
    return res;
  }

}