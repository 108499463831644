<div class="container job-padding mt-0">
  <div class="row mx-0 mb-4 pt-5">
    <div class="h1 page-name">
      <span>{{'createNewJob'|translate}}</span>
    </div>
  </div>
  <div class="row mb-3 step-section col-md">
    <div class=" h5-gray mb-2">{{'step'|translate}} 1 OF 2</div>
  </div>
  <div class="row">
    <div class="col-md px-0">

      <div class="mt-0 px-3">
        <form [formGroup]="addNewJobForm">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'job.name'|translate}}</label>
              <input formControlName="jobName" [maxlength] = "limitName" class="input-field mw-100" type="text"
              [ngClass]="{'input-danger': isJobNameHasErrors}">
              <div *ngIf="isJobNameHasErrors">
                <p *ngIf="jobNameControl.errors.required" class="small error-text">
                  {{'errors.canNotBeEmpty' | translate}}
                </p>
              </div>
           </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'job.jobType'|translate}}</label>
              <p-multiSelect formControlName="jobTypes" [options]="_utilities.catJobType"
                  [showHeader]="isJobTypesSelected"
                  optionLabel="opt"
                  class='multiselect-custom'
                  [filter]="false" [showToggleAll]="false"
                  [ngClass]="{'multiselect-custom-error': isJobTypesHasErrors}">
                <p-header>
                  <span class="cus-item cus-item-value" *ngFor="let selected of jobTypesControl.value">
                    {{selected}}
                  </span>
                </p-header>
                <ng-template let-value pTemplate="selectedItems">
                  <span class="cus-item cus-item-value" [ngClass]="{'input-danger': isJobTypesHasErrors}"
                    *ngFor="let selected of jobTypesControl.value">
                    {{selected}}
                  </span>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <div class="cus-item">
                    <div>{{type.value}}</div>
                  </div>
                </ng-template>
              </p-multiSelect>
              <div *ngIf="isJobTypesHasErrors">
                <p class="small error-text">
                  {{'select.jobType' | translate}}
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'job.jobPurpose'|translate}}</label>
              <select formControlName="jobPurpose" class="select-dropdown mw-100"
                [ngClass]="{'input-danger': isJobPurposeHasErrors}">
                <option *ngFor="let item of _utilities.catJobPurpose" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isJobPurposeHasErrors">
                <p *ngIf="jobPurposeControl.errors.required" class="small error-text">
                  {{'select.jobPurpose' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'job.jobDetails.customer'|translate}}</label>
              <select formControlName="jobCustomerType" class="select-dropdown mw-100"
                [ngClass]="{'input-danger': isJobCustomerTypeHasErrors}">
                <option *ngFor="let item of _utilities.catJobCustomer" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isJobCustomerTypeHasErrors">
                <p *ngIf="jobCustomerTypeControl.errors.required" class="small error-text">
                  {{'select.jobCustomerType' | translate}}
                </p>
              </div>
            </div>
          </div>

          <hr class="form-border mb-0">
          <div class="row pull-right mt-4">
            <button (click)="cancelCreateJob()" value="{{'buttons.cancel'|translate}}"
              class="bttn bttn-secondary">{{'buttons.cancel'|translate}}</button>
            <button (click)="nextStepCreate()" type="submit" class="bttn bttn-primary">{{'buttons.next'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>