import { DataLogicService } from './../data-logic.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { Config } from 'src/app/core/services/config';
import * as LS from 'src/app/utils/localstorage';
import * as OB from 'src/app/utils/objecter';
import { Observable } from 'rxjs';
import { Item, QueryData } from '../../models/common.model';
import { ACTION_TYPE, API_RESPONSE } from 'src/app/shared/enums/enums';
import { switchMap } from 'rxjs/operators';
import { CatalogType } from 'src/app/catalogs-management/catalog-setup/catalog-setup.component';

@Injectable({
  providedIn: 'root'
})
export class CatalogsManagementService {
  constructor(
    public dataLogic: DataLogicService,
    public api: ApiService,
  ) { }

  /**
   * Get Additional Fluid Properties Catalog
   */
  public getCollectionInfo(): Observable<any> {
    const url = Config.APIUrlCatalogs + environment.catalogs.collectionInfo;
    return new Observable(ob => {
      this.api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
      (error) => {
        ob.next([]);
        ob.complete();
      });
    });
  }

  public async updateCatalogVersion(payload) {
    let url = Config.APIUrlCatalogs + environment.catalogs.updateVersion;
    return await this.api.post(url, payload).toPromise()
      .then(res => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public getlatestVersion(): Observable<any> {
    const url = Config.APIUrlCatalogs + environment.catalogs.latestVersion;
    return new Observable(ob => {
      this.api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
        (error) => {
          ob.next(0);
          ob.complete();
        });
    });
  }

  public getCatalog(collection, property): Observable<any>  {
    let url = Config.APIUrlCatalogs + environment.catalogs.collectionData.replace("{collection}", collection);
    if(property) {
      url = url + "/property/" + property;
    }

    return new Observable(ob => {
      this.api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
      (error) => {
        ob.next([]);
        ob.complete();
      });
    });
  }

  public getCatalogItem(query: QueryData): Observable<Array<Item>>  {
    let url = Config.APIUrlCatalogs + environment.catalogs.collectionData.replace("{collection}", query.collection);
    if(query.property) {
      url = url + "/property/" + query.property;
    }

    return new Observable(ob => {
      this.api.get<any>(url).subscribe(res => {
        ob.next(res.map(x=> {
          return {
            label: OB.getObj(x, query.mapper.label),
            value: OB.getObj(x, query.mapper.value),
            child: query.mapper.child ? OB.getObj(x, query.mapper.child): null
          }
        }));
        ob.complete();
      },
      (error) => {
        ob.next([]);
        ob.complete();
      });
    });
  }

  public async updateCatalog(payload: any) {
    const key = LS.LOCAL_STORAGE_KEY.Data.CatalogSetup.CreateUpdate;
    const url = Config.APIUrlCatalogs + environment.catalogs.updateCollection;
    return this.dataLogic.postAsync(url, payload, key);
  }

  public updateCatalogCountry(oldName, newName, action): Observable<any> {
    const collectionName = "catalog.countries";
    const url = Config.APIUrlCatalogs + environment.catalogs.updateCollection;
    return this.getCatalog(collectionName, null).pipe(switchMap(countries => {
      let data = { country: newName };
      if(action != ACTION_TYPE.CREATE) {
        const found = countries.find(x => x.country == oldName);
        if(found) {
          data = found;
          data.country = newName;
        }
      }

      const payload = {
        collectionName: collectionName,
        type: CatalogType.Object,
        action: action,
        data: data
      };
      return this.api.post(url, payload);
    }));
  }


  public async createMany(payload: any) {
    const url = Config.APIUrlCatalogs + environment.catalogs.createMany;
    return this.dataLogic.postAsync(url, payload);
  }

}
