export class Well {
    public jobId: string;
    public wellType: string;
    public isTightHole: boolean;
    public isCriticalFirstWell: boolean;
    public wellWITSML: WellWITSML;
  
    constructor(data) {
      this.jobId = data ? data.jobId : null;
      this.wellType = data ? data.wellType : null;
      this.isTightHole = data ? data.isTightHole : null;
      this.isCriticalFirstWell = data ? data.isCriticalFirstWell : null;
      this.wellWITSML = data ? new WellWITSML(data.wellWITSML || data) : null;
    }
  }
  
  class WellWITSML {
    public wellId: string;
    public nameLegal: string;
    public numAPI: string;
    public statusWell: string;
    public purposesWell: string[];
    public latitude: number;
    public longitude: number;
    public dTimSpud: string;
    public region: string;
    public country: string;
    public state: string;
    public basin: string;
    public field: string;
    public operator: string;
    public timezone: string;
    public waterDepth: string;
  
    constructor(data) {
      this.wellId = data ? data.wellId : null;
      this.nameLegal = data ? data.nameLegal : null;
      this.numAPI = data ? data.numAPI : null;
      this.statusWell = data ? data.statusWell : null;
      this.purposesWell = data ? data.purposesWell : null;
      this.latitude = data ? data.latitude : null;
      this.longitude = data ? data.longitude : null;
      this.dTimSpud = data ? data.dTimSpud : null;
      this.region = data ? data.region : null;
      this.country = data ? data.country : null;
      this.state = data ? data.state : null;
      this.basin = data ? data.basin : null;
      this.field = data ? data.field : null;
      this.operator = data ? data.operator : null;
      this.timezone = data ? data.timezone : null;
      this.waterDepth = data ? data.waterDepth : null;
    }
  }
  